#block-system-main-menu {
  @include rem(margin-top, 10px);
  flex: 0 0 100%;
  width: 100%;

  @include xs {
    display: none;
    position: absolute;
    top: 100px;
    background: $white;
    z-index: 100;
    border: 1px solid $gray-lighter;
  }

  ul.menu {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    @include xs {
      display: block;
    }

    li {
      list-style: none;

      @include xs {
        padding: 0;
        margin: 0;
      }

      &.first {
        a {
          &:hover,
          &:focus {
            color: $secondary-color;
          }
        }
      }

      // &.last {
      //   flex: 1 1 auto;
      //   text-align: right;
      // }

      a {
        @include rem(font-size, 15px);
        @include rem(padding-left, 15px);
        @include rem(padding-right, 15px);
        font-weight: 600;
        text-decoration: none;
        color: $black;

        @include xs {
          @include rem(padding, 10px);
          border-bottom: 1px solid $gray-lighter;
          display: block;
        }

        &.active-trail,
        &:hover,
        &:focus {
          color: $primary-color-darker;
        }

        &.btn {
          &.active,
          &.active-trail {
            color: $white;
          }

          &:hover,
          &:focus {
            color: $white;
          }

          @include xs {
            border-bottom: none;
          }
        }

        &.btn-primary {
          background: $primary-color;
          color: $white;

          &.active-trail,
          &:hover,
          &:focus {
            background: $primary-color-darker;
            color: $white;
          }
        }

        &.btn-encyclo {
          background: $secondary-color;
          color: $white;

          &.active-trail,
          &:hover,
          &:focus {
            background: $secondary-color-darker;
            color: $white;
          }
        }
      }
    }
  }
}

#block-menu-menu-profile-menu {
  ul.menu {
    margin: 0;
    padding: 0;

    li {
      @include rem(margin, 0 0 10px);
      list-style: none;
      padding: 0;

      a {
        text-transform: uppercase;
        color: $black;
        font-weight: 600;

        &.active-trail {
          color: $primary-color-darker;
        }

        &:hover,
        &:focus {
          text-decoration: none;
          color: $primary-color-darker;
        }

        &:before {
          @include rem(margin-right, 5px);
          width: 25px;
          text-align: center;
        }

        &.icon-perso {
          &:before {
            @include custom-font('\EA49');
          }
        }

        &.icon-purchase {
          &:before {
            @include custom-font('\EA4A');
          }
        }

        &.icon-favorite {
          &:before {
            @include custom-font('\EA1E');
          }
        }

        &.icon-newspaper {
          &:before {
            @include custom-font('\EA56');
          }
        }

        &.icon-list {
          &:before {
            @include custom-font('\EA48');
          }
        }

        &.logout {
          color: $red;

          &:before {
            @include custom-font('\EA5C');
          }
        }
      }
    }
  }
}

#block-multiblock-1 {
  position: relative;

  h2 {
    @include rem(font-size, 14px);
    font-weight: normal;
    font-family: $global-font-family;

    &:after {
      @include rem(margin-left, 10px);
      display: inline-block;
      content: '';
      border-top: 5px solid $black;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      vertical-align: middle;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .content {
    @include rem(padding, 10px);
    display: none;
    background: $white;
    border: 1px solid $gray-lighter;
    position: absolute;
    right: 0;
    z-index: 150;

    &:before {
      display: inline-block;
      content: '';
      border-bottom: 10px solid $white;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      vertical-align: middle;
      position: absolute;
      top: -10px;
      right: 50px;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        margin: 0;
        padding: 0;

        a {
          @include rem(padding, 5px 5px 5px 0);
          white-space: nowrap;
          color: $black;
          display: block;

          &:hover,
          &:focus {
            text-decoration: none;
            color: $primary-color;
          }

          &:before {
            @include rem(margin-right, 5px);
            width: 25px;
            text-align: center;
          }

          &.icon-perso {
            &:before {
              @include custom-font('\EA49');
            }
          }

          &.icon-purchase {
            &:before {
              @include custom-font('\EA4A');
            }
          }

          &.icon-newspaper {
            &:before {
              @include custom-font('\EA56');
            }
          }

          &.icon-favorite {
            &:before {
              @include custom-font('\EA1E');
            }
          }

          &.icon-list {
            &:before {
              @include custom-font('\EA48');
            }
          }

          &.logout {
            color: $red;

            &:before {
              @include custom-font('\EA5C');
            }
          }
        }
      }
    }
  }
}

#block-locale-language-content {
  @include rem(margin-left, 20px);

  ul.language-switcher-locale-url {
    margin: 0;
    padding: 0;
    display: flex;

    li {
      list-style: none;

      a {
        @include rem(margin-left, 10px);
        color: $black;
        display: inline-block;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;

        &:hover,
        &:focus {
          color: $primary-color;
        }

        &.active {
          background: $gray;
          color: $white;
        }
      }
    }
  }
}

#block-menu-menu-mundi-plantarum {
  .region-sidebar-first & {
    ul.menu {
      li {
        a {
          &.active,
          &:focus,
          &:hover {
            color: $secondary-color;
          }
        }
      }
    }
  }
}
