body {
  overflow-x: hidden;
}

.container {
  @extend %container;
}

.page-eshop,
.page-encyclopedie {
  .layout-3col__right-content {
    > h1 {
      &:after {
        @extend %button;
        @extend %button-primary;
        display: none;
        content: "Filtrer les résultats";
        color: $black;
        @include rem(font-size, 16px);
        @include rem(padding, 10px 0);
        @include rem(margin, 20px 0);
        font-weight: 600;
        line-height: 1;

        @include xs {
          display: block;
        }
      }
    }
  }
}

.page-encyclopedie {
  .layout-3col__right-content {
    > h1 {
      &:after {
        background: $secondary-color-lighter;
      }

      &:hover,
      &:focus {

        &:after {
          background: $secondary-color;
        }
      }
    }
  }
}

.layout-3col__left-content {
  .container {
    display: flex;

    @include xs {
      display: block;
    }

    aside {
      width: 25%;

      @include xs {
        width: 100%;
        display: none;
      }
    }

    #maincontent {
      width: 75%;

      @include xs {
        width: 100%;
      }
    }
  }
}

.layout-3col__right-content {
  .page-events &,
  .node-type-abonnement &{
    .container {
      aside {
        @include xs {
          @include rem(margin-bottom, 50px);
          display: block;
        }
      }
    }
  }

  .container {
    display: flex;

    @include xs {
      display: block;
    }

    aside {
      width: 25%;

      @include xs {
        width: 100%;
        display: none;
      }
    }

    #maincontent {
      width: 75%;

      @include xs {
        width: 100%;
      }
    }
  }
}

.node-type-livres {
  #block-views-eshop-block-7 {
    width: 33%;
    margin-left: 5%;

    @include xs {
      width: 100%;
      margin-left: 0;
    }
  }

  #block-views-eshop-block-6 {
    width: 62%;

    @include xs {
      width: 100%;
    }
  }
}

.node-type-livres {
  #block-views-eshop-block-7 {
    width: 33%;
    margin-left: 5%;

    @include xs {
      width: 100%;
      margin-left: 0;
    }
  }

  #block-views-eshop-block-6 {
    width: 62%;

    @include xs {
      width: 100%;
    }
  }
}

.node-type-encyclopedie {
  .region-content {
    display: flex;
    flex-wrap: wrap;
  }

  #block-system-main,
  #block-views-encyclop-die-block-3 {
    flex: 0 0 100%;
  }

  #block-views-encyclop-die-block-2 {
    width: 55%;

    @include xs {
      width: 100%;
    }
  }

  #block-views-encyclop-die-block-1 {
    width: 40%;
    margin-left: 5%;

    @include xs {
      width: 100%;
      margin-left: 0;
    }
  }
}

.node-type-plantes {
  .region-content {
    display: flex;
    flex-wrap: wrap;

    #block-views-eshop-block-1 {
      width: 33%;
      margin-left: 5%;

      @include xs {
        width: 100%;
        margin-left: 0;
      }
    }

    #block-views-eshop-block-2 {
      width: 62%;

      @include xs {
        width: 100%;
      }
    }

    #block-views-eshop-block-3 {
      flex: 0 0 100%;
    }
  }
}

.grid-sizer {
  width: calc( ( 100% - 36px ) /  4 );
  margin: 0 !important;
  padding: 0 !important;

  @include sm {
    width: calc( ( 100% - 24px ) / 3);
  }

  @include xs {
    width: calc( ( 100% - 12px ) / 2);
  }

}

.grid-item {
  width: calc( ( 100% - 36px ) /  4 );

  @include sm {
    width: calc( ( 100% - 24px ) / 3);
  }

  @include xs {
    width: calc( ( 100% - 12px ) / 2);
  }

}

.grid-item--width2 {
  width: calc( ( 100% - 12px ) /  2 );
}
