.ajax-progress-throbber { display: none}
#main {
  position: relative;

  > h1 {
    @extend %container;
  }
}

.node-type-contact {
  #maincontent {
    h1 {
      display: none;
    }
  }

  .region-content {
    display: flex;

    @include xs {
      display: block;
    }
  }
}

.page-eshop {
  #maincontent {
    > h1 {
      display: none;
    }
  }
}

.node-type-product-display,
.node-type-plantes {
  #maincontent,
  #main {
    > h1 {
      display: none;
    }
  }
}

.node-type-plantes,
.node-type-livres {
  .node.view-mode-full {
    @extend %container;
    max-width: 80%;
  }
}

.front {
  #block-system-main {
    display: none;
  }
}

.node-type-livres {
  #block-system-main {
    @include rem(margin-top, 50px);
    width: 100%;
    flex: 0 0 100%;
  }
}

.page-mon-compte {
  .region-sidebar-first {
    border-right: 0;
  }
}

.front {
  background: $gray-lightest;

  .region-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include xs {
      display: block;
    }
  }
}

.node-type-article {
  .views-field-field-a-savoir {
    @include rem(margin-top, 70px);
    @include rem(padding, 20px 0);
    background: $primary-color-lighter;
    position: relative;

    &:before,
    &:after {
      display: block;
      content: "";
      background-color: $primary-color-lighter;
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
    }

    &:before {
      left: -50%;
    }

    &:after {
      right: -50%;
    }
  }

  .views-field-body,
  .views-label-field-a-savoir,
  .views-field-field-a-savoir .field-content {
    @include rem(margin-left, 200px);
    @include rem(margin-right, 200px);

    @include xs {
      @include rem(margin-left, 0);
      @include rem(margin-right, 0);
    }
  }

  .views-field-body {
    @include xs {
      @include rem(margin-left, 120px);
      @include rem(margin-right, 0px);
    }
  }

  #comments {
    @include rem(padding-bottom, 70px);
    @include rem(padding-top, 30px);
    background: $gray-lightest;
    position: relative;

    &:before,
    &:after {
      display: block;
      background: $gray-lightest;
      content:"";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50%;
      z-index: -1;
    }

    &:before {
      left: -50%;
    }

    &:after {
      right: -50%;
    }

    .title {
      width: 600px;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    .comment-block {
      background: $white;
      width: 600px;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      box-sizing: border-box;

      #edit-subject {
        display: none;
      }
    }

    .comments-wrapper {
      width: 600px;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;

      .comment {
        @include rem(padding, 10px 20px);
        background: $white;
      }
    }
  }
}

.node-type-faq {
  #block-views-header-block {

    .views-field-field-intro {
      margin-bottom: 0;
    }
  }

  .field-name-body {
    @include rem(margin-left, 200px);
    @include rem(margin-right, 200px);

    @include xs {
      @include rem(margin-left, 120px);
      @include rem(margin-right, 0);
    }
  }
}

.node-type-agenda {
  .views-field-body,
  .views-field-field-site-web,
  .views-field-field-presse {
    @include rem(margin-left, 200px);
    @include rem(margin-right, 200px);

    @include xs {
      @include rem(margin-left, 120px);
      @include rem(margin-right, 0px);
    }
  }

  .views-field-field-site-web {
    @include rem(margin-bottom, 10px);
  }
}

.page-agenda {
  h2 {
    @include rem(font-size, $headings-1-font-size);
  }
}

.not-logged-in {
  .form-item-quantity {
    display: none;
  }

  .form-item-attributes-field-taille {
    @include rem(margin-bottom, 25px);
  }
}

.confirm-parent, .password-parent {
  width: 100%;
}

.node-type-encyclopedie {
  .field-name-field-famille {
    color: darken($gray, 10%);
  }
}

#block-views-license-block,
#block-views-license-block-1,
#block-views-license-block-2 {
  h2 {
    font-family: $global-font-family;
  }
  .view-empty,
  .views-field-nothing {
    a {
      @extend %button;
      @extend %button-secondary;
      @include rem(margin-right, 10px);
      @include rem(padding, 10px 20px);
      @include rem(font-size, 14px);
      border: 1px solid $primary-color;
      text-transform:uppercase;

      &:hover,
      &:focus {
        border-color: $primary-color-darker;
      }

      &.btn {

        &-outline {
          color: $red;
          border-color: $red;
          background: none;

          &:hover,
          &:focus {
            background: $red;
            color: $white;
            border-color: $red;
          }
        }
      }
    }
  }
}

.page-user-edit {
  #maincontent {
    .tabs {
      display: none;
    }

    #edit-account {
      .form-item {
        margin-top: 0;
      }
    }

    label {
      color: $black;
      font-weight: 500;
    }

    input {
      box-sizing: border-box;
    }

    .form-item-name {
      @include rem(margin-bottom, 20px);
      label {
        text-transform: uppercase;
        font-weight: bold;
      }

      input {
        @include rem(margin, 10px 0 5px);
        width: 100%;
      }
    }

    .form-item-current-pass {
      @include rem(padding, 20px);
      @include rem(margin-bottom, 40px);
      background: $red-lightest;
      border: 1px solid $red-lighter;

      .placeholder {
        color: $red;
      }

      a {
        color: $black;
      }

      input {
        @include rem(margin, 10px 0 5px);
        width: 100%;
      }
    }

    .form-item-mail {
      @include rem(margin-bottom, 30px);

      label {
        text-transform: uppercase;
        font-weight: bold;
      }
      input {
        @include rem(margin, 10px 0 5px);
        width: 100%;
      }
    }

    .form-item-pass {
      @include rem(margin-bottom, 30px);
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: flex-start;

      .form-item {
        flex: 1;

        input {
          width: 100%;
        }
      }

      .form-item-pass-pass1 {
        @include rem(margin-right, 10px);
      }

      div.password-confirm {
        @include rem(margin, 0 0 20px);
        float: none;
        text-align: right;
        width: 100%;
      }
      .password-strength {
        @include rem(margin, 0 0 20px);
        float: none;
      }

      .description,
      .password-suggestions {
        flex: 0 0 100%;
        box-sizing: border-box;
      }

      .password-suggestions {
        @include rem(padding, 15px);
        background: $primary-color-lighter;
        border: 1px solid $primary-color;
      }
    }

    #edit-locale {
      display: none;
    }

    fieldset {
      @include rem(margin, 0 0 30px);
      border: none;
      padding: 0;

      legend {
        text-transform: uppercase;
        font-weight: bold;
        color: $black;

        a {
          color: $black;
        }
      }
    }
  }
}

.page-encyclopedie {
  .form-type-bef-checkbox {
    label {
      &:before {
        background: $secondary-color-lighter;
      }
    }

    input[type="checkbox"]{
      &:checked + label {
        &:after {
          color: $secondary-color-darker;
        }
      }
    }
  }
}

.role-premium {
  #block-multiblock-1 {
    position: relative;

    h2 {
      &:before {
        @include custom-font('\EA5F');
        @include rem(font-size, 18px);
        color: $secondary-color;
        vertical-align: super;
      }
    }
  }
}

//Mundi Plantarum color
.page-node-3235,
.page-node-3260,
.page-node-3261,
.page-node-3236,
.node-type-abonnement,
.page-checkout-review-direct,
.page-encyclopedie {
  #block-views-header-block {
    .views-field-field-intro {
      h1 {
        color: $secondary-color-lighter;
      }
    }
  }

  .highlighted {
    background: $secondary-color-lighter;
    border-color: $secondary-color;
    color: $secondary-color-darker;
  }

  #block-easy-breadcrumb-easy-breadcrumb {
    .content {
      .easy-breadcrumb {
        span {
          a {
            color: $secondary-color;
          }
        }
      }
    }
  }

  .form-submit.checkout-continue {
    background: $secondary-color;

    &:hover,
    &:before {
      background: $secondary-color-darker;
    }
  }

  .checkout-buttons {
    .fieldset-wrapper {
      &:before {
        color: $secondary-color;
      }
    }

    .checkout-cancel,
    .checkout-back {
      color: $secondary-color;
    }
  }

  .form-type-select {
    select {
      background-color: $secondary-color-lighter;
      border-color: $secondary-color;
      color: $secondary-color;
    }
  }

  #prefooter {
    background: $secondary-color;

    form {
      .webform-submit {
        background: $secondary-color-darker;
      }
    }
  }

  footer {
    .copyright {
      background: $secondary-color-lighter;
      color: $secondary-color-darker;

      a {
        color: $secondary-color;

        &:hover,
        &:focus {
          color: $secondary-color-darker;
        }
      }
    }
  }
}

.page-checkout-review-direct {
  .commerce_payment {
    clear: both;
  }
}

#commerce-checkout-form-login {
  .commerce-extra-login-page-description {
    font-size: $headings-2-font-size;
    font-family: $headings-font-family;
    font-weight: bold;
    text-align: center;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    color: $black;
  }

  .checkout-buttons {
    @include rem(margin-top, 50px);
  }

  .login_pane {
    input {
      @include rem(margin-top, 5px);
      width: 60%;
      max-width: 400px;
    }
  }
}

.node-type-abonnement {
  #main {
    @include rem(padding-top, 40px);
    > h1 {
      display: none;
    }
  }

  h4 {
    color: $secondary-color;
  }
}
