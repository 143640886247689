$enable-legacy-support-for-ie8: true;
$container-width:               1120px;
/* ------------------------
 * COLORS
 * -----------------------*/
 $gray-lightest:                #f1f1f1;
 $gray-lighter:                 #eaeaea;
 $gray:                         #cacaca;
 $gray-darker:                  #4f4f4f;

 $red-lightest:                 #fff5f5;
 $red-lighter:                  #f6dfdf;
 $red:                          #ff0000;

 $white:                        #fff;

 $black-lighter:                #969696;
 $black:                        #4a4a4a;

 $primary-color-lighter:        #e9fff3;
 $primary-color:                #5dd698;
 $primary-color-darker:         #1b9455;

 $secondary-color-lighter:      #E3F2F9;
 $secondary-color:              #7295c9;
 $secondary-color-darker:       #243c69;

 $success-color:                #5cd5d2;
 $warning-color:                #eccb6c;
 $error-color:                  #fef5f1;

 $silver:                       #c8c8d2;
 $blue:                         #0165a9;
 $crema:                        #dfd9c4;
 $yellow:                       #ffef6d;
 $orange:                       #ff9329;
 $purpre:                       #510e3a;
 $pink:                         #ffa7f3;
 $red-tax:                      #dc3332;
 $green:                        #a2ce60;
 $brown:                        #694342;
 $purple:                       #8e84bc;
 $violet:                       #79217f;
 $black-tax:                    #000;

 $fb-color:                     #1B5D9C;
 $insta-color:                  #7100c1;
 $youtube-color:                #EA3223;

/* ------------------------
 * FONTS
 * -----------------------*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Raleway:400,700,800');

$global-font-size:              16px;
$global-line-height:            1.5;
$global-font-family:            'Open Sans', sans-serif;

// Headings
$headings-1-font-size:          24px;
$headings-2-font-size:          18px;
$headings-3-font-size:          16px;
$headings-4-font-size:          15px;
$headings-5-font-size:          15px;
$headings-6-font-size:          16px;

$headings-color:                $black;
$headings-font-family:          'Raleway', sans-serif;
$headings-font-weight:          700;
$headings-margins:              0 0 30px 0;

/* -----------------------
 * Mediaqueries
 * ---------------------*/

 //== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

$screen-xxs: 320px;
// Extra small screen / phone
// Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
// Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
// Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
// Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 700px;
$screen-sm-min: $screen-sm;
// Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
// Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
// Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
// Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
// Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-xlg-min: 1781px;
