.mobile-menu {
	display:none;
	width:40px;
	height:40px;
	text-align: center;
	right: 0;
	top: 0;
	z-index: 384;
	padding: 5px;

  @include xs {
    display: block;
    position: absolute;
    top: 70px;
    right: -12px;
  }

	span {
		position:relative;
		display:block;
		margin: 18px 8px;
		&:after,
  	&:before {
  		 content:"";
  		 position:absolute;
  		 left: 0;
  	}
	}
	span,
	span:after,
	span:before {
		height:4px;
		width:25px;
		text-indent:-9999px;
		background-color: $black;
		transition:all .25s ease-out;
		border-radius: 3px;
	}
	span:before {
		top:-8px;
	}
	span:after {
	 top:8px;
	}
}

.open {
	.mobile-menu{
  	border:none;
  	span:before,
  	span:after{
  		top:0;
  	}
  	span {
  		background-color:rgba(0,0,0,0);
  		&:after{
  		 -webkit-transform: translateY(0) rotate(-45deg);
  		 transform: translateY(0) rotate(-45deg);
  		}
  		&:before{
  		 -webkit-transform: translateY(0) rotate(45deg);
  		 transform: translateY(0) rotate(45deg);
  		}
  	}
	}
}

.slick-next {
  @include rem(padding, 30px 0);
  height: auto;
  background: $gray-lighter;

  &:before {
    @include custom-font('\EA52');
  }

  &:hover,
  &:focus {
    background: $primary-color;
  }
}

.slick-prev {
  @include rem(padding, 30px 0);
  height: auto;
  background: $gray-lighter;

  &:before {
    @include custom-font('\EA53');
  }

  &:hover,
  &:focus {
    background: $primary-color;
  }
}
