#commerce-checkout-form-billing-shipping {
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .checkout-buttons {
    width: 100%;
  }
}

.customer_profile_billing,
.customer_profile_shipping {
  border: none;
  padding: 0;
  margin: 0;
  width: 40%;

  @include xs {
    width: 100%;
  }

  legend {
    @include rem(font-size, 15px);
    color: $black;
    text-transform: uppercase;
  }
}
#commerce-checkout-form-shipping {
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
#customer-profile-shipping-ajax-wrapper {
  @include rem(margin-bottom, 50px);
  width: 40%;
  float: left;

  fieldset.customer_profile_shipping {
    width: 100%;
  }
}

.customer_profile_billing {
  @include rem(margin-bottom, 50px);
  margin-right: 20%;
  float: left;

  @include xs {
    margin-right: 0;
    float: none;
  }
}

.name-block,
.street-block,
.field-name-field-tva,
.field-name-field-phone {
  .form-item {
    float: none;
  }
  input {
    box-sizing: border-box;
    width: 100%;
  }
}

#commerce-shipping-service-ajax-wrapper {
  @include rem(margin, 50px 0);
  clear: both;
  width: 100%;

  fieldset {
    border: none;
    padding: 0;
  }

  legend {
    @include rem(font-size, 15px);
    @include rem(padding, 5px 0);
    @include rem(margin-bottom, 20px);
    color: $black;
    text-transform: uppercase;
    display: block;
    width: 100%;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;

    .shipping-details {
      color: $gray;
      float: right;
    }
  }

.form-item {
  @include rem(margin-bottom, 30px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.avenirchercher {
    justify-content: flex-start;
  }

  &.dpd {
    height: 63px;
    background: url('../images/logo-dpd.png') 50px center no-repeat;

    /* ECRAN RETINA */
    @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
    only screen and (-o-max-device-pixel-ratio: 3/2),
    only screen and (max--moz-device-pixel-ratio: 1.5),
    only screen and (max-device-pixel-ratio: 1.5) {
    	background: url('../images/logo-dpd.png') 50px center no-repeat;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    	background: url('../images/logo-dpd@2x.png') 50px center no-repeat;
      background-size: 152px 63px;
    }
  }

  &.bpost {
    height: 63px;
    background: url('../images/logo-bpost.png') 50px center no-repeat;

    /* ECRAN RETINA */
    @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
    only screen and (-o-max-device-pixel-ratio: 3/2),
    only screen and (max--moz-device-pixel-ratio: 1.5),
    only screen and (max-device-pixel-ratio: 1.5) {
    	background: url('../images/logo-bpost.png') 50px center no-repeat;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    	background: url('../images/logo-bpost@2x.png') 50px center no-repeat;
      background-size: 115px 63px;
    }
  }

  input {
    display: none;

    &:checked + label{
      &:before {
        background: $gray-darker;
      }
    }
  }

  label {
    flex: 1 1 auto;
    font-size: 0;

    .avenirchercher & {
      flex: 0;
    }

    &:before {
      display: block;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 2px solid $white;
      background: $white;
      box-shadow: 0 0 0 2px $gray-darker;
    }
  }
}

  input[id^="edit-commerce-shipping-shipping-service-bpost"] + label {
    width: 115px;
    height: 63px;
    display: flex;
    align-items: center;
    background: url('../images/logo-bpost.png') 50px center no-repeat;
    /* ECRAN RETINA */
    @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
    only screen and (-o-max-device-pixel-ratio: 3/2),
    only screen and (max--moz-device-pixel-ratio: 1.5),
    only screen and (max-device-pixel-ratio: 1.5) {
    	background: url('../images/logo-bpost.png') 50px center no-repeat;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
      background: url('../images/logo-bpost@2x.png') 50px center no-repeat;
      background-size: 115px 63px;
    }
  }

  input[id^="edit-commerce-shipping-shipping-service-dpd"] + label {
    width: 152px;
    height: 63px;
    display: flex;
    align-items: center;
    background: url('../images/logo-dpd.png') 50px center no-repeat;
    /* ECRAN RETINA */
    @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
    only screen and (-o-max-device-pixel-ratio: 3/2),
    only screen and (max--moz-device-pixel-ratio: 1.5),
    only screen and (max-device-pixel-ratio: 1.5) {
    	background: url('../images/logo-dpd.png') 50px center no-repeat;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
      background: url('../images/logo-dpd@2x.png') 50px center no-repeat;
      background-size: 152px 63px;
    }
  }

  .description {
    @include rem(font-size, 16px);
    color: $gray-darker;
    font-weight: 600;
    .avenirchercher & {
      max-width: 80%;
    }
  }
}

.checkout-completion-message {
  text-align: center;

  h2 {
    @include rem(padding, 5px 0);
    @include rem(margin-bottom, 40px);
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
  }

  h3 {
    @include rem(margin-top, 50px);
  }

  strong {
    color: $primary-color;
  }

  .btn {
    @include rem(margin-top, 50px);
  }
}

.commerce_payment {
  @include rem(margin, 80px 0);
  padding: 0;
  border: none;
  min-width: 1px;

  .form-radios {
    display: flex;
    align-items: center;
    justify-content: center;

    @include xs {
      flex-wrap: wrap;
    }
  }

  legend {
    @include rem(font-size, 15px);
    @include rem(padding, 5px 0);
    @include rem(margin-bottom, 20px);
    color: $gray-darker;
    text-transform: uppercase;
    display: block;
    width: 100%;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    font-weight: 600;
  }

  .form-item-commerce-payment-payment-method {
    @include rem(margin, 30px);

    &.bancontact {
      width: 143px;
      height: 130px;
      background: url('../images/logo-bancontact.png') center bottom no-repeat;

      /* ECRAN RETINA */
      @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
      only screen and (-o-max-device-pixel-ratio: 3/2),
      only screen and (max--moz-device-pixel-ratio: 1.5),
      only screen and (max-device-pixel-ratio: 1.5) {
      	background: url('../images/logo-bancontact.png') center bottom no-repeat;
      }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
      only screen and (-o-min-device-pixel-ratio: 3/2),
      only screen and (min--moz-device-pixel-ratio: 1.5),
      only screen and (min-device-pixel-ratio: 1.5) {
      	background: url('../images/logo-bancontact@2x.png') center bottom no-repeat;
        background-size: 143px 100px;
      }
    }

    &.cartesdecredit {
      width: 264px;
      height: 130px;
      background: url('../images/logo-credit.png') center 50px no-repeat;

      /* ECRAN RETINA */
      @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
      only screen and (-o-max-device-pixel-ratio: 3/2),
      only screen and (max--moz-device-pixel-ratio: 1.5),
      only screen and (max-device-pixel-ratio: 1.5) {
      	background: url('../images/logo-credit.png') center 50px no-repeat;
      }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
      only screen and (-o-min-device-pixel-ratio: 3/2),
      only screen and (min--moz-device-pixel-ratio: 1.5),
      only screen and (min-device-pixel-ratio: 1.5) {
      	background: url('../images/logo-credit@2x.png') center 50px no-repeat;
        background-size: 264px 64px;
      }
    }

    &.virement {
      width: 136px;
      height: 130px;
      background: url('../images/logo-virement.png') center 50px no-repeat;

      /* ECRAN RETINA */
      @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
      only screen and (-o-max-device-pixel-ratio: 3/2),
      only screen and (max--moz-device-pixel-ratio: 1.5),
      only screen and (max-device-pixel-ratio: 1.5) {
      	background: url('../images/logo-virement.png') center 50px no-repeat;
      }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
      only screen and (-o-min-device-pixel-ratio: 3/2),
      only screen and (min--moz-device-pixel-ratio: 1.5),
      only screen and (min-device-pixel-ratio: 1.5) {
      	background: url('../images/logo-virement@2x.png') center 50px no-repeat;
        background-size: 136px 55px;
      }
    }

    &.domiciliation {
      @include rem(margin-left, 30px);
      height: 130px;
      background: url('../images/domiciliation.svg') center 40px no-repeat;
      background-size: auto 60px;

      label {
        @include rem(font-size, 18px);
        color: $black;
        font-family: $headings-font-family;
        font-weight: 700;


        &:before {
          margin-bottom: 100px;
        }
      }
    }

    input {
      display: none;

      &:checked + label{
        &:before {
          background: $gray-darker;
        }
      }
    }

    label {
      font-size: 0;
      display: block;
      text-align: center;

      &:hover {
        cursor: pointer;
      }

      &:before {
        display: block;
        margin-left: auto;
        margin-right: auto;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid $white;
        background: $white;
        box-shadow: 0 0 0 2px $gray-darker;
      }
    }


  }

  #edit-commerce-payment-payment-method-payment-commerce-2commerce-payment-payment-commerce-2 {
    + label {
      background: url('../images/logo-bancontact.png') center 30px no-repeat;
      width: 143px;
      height: 130px;

      /* ECRAN RETINA */
      @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
      only screen and (-o-max-device-pixel-ratio: 3/2),
      only screen and (max--moz-device-pixel-ratio: 1.5),
      only screen and (max-device-pixel-ratio: 1.5) {
      	background: url('../images/logo-bancontact.png') center 30px no-repeat;
      }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
      only screen and (-o-min-device-pixel-ratio: 3/2),
      only screen and (min--moz-device-pixel-ratio: 1.5),
      only screen and (min-device-pixel-ratio: 1.5) {
      	background: url('../images/logo-bancontact@2x.png') center 30px no-repeat;
        background-size: 143px 100px;
      }
    }
  }

  #edit-commerce-payment-payment-method-payment-commerce-3commerce-payment-payment-commerce-3 {
    + label {
      background: url('../images/logo-credit.png') center 50px no-repeat;
      width: 264px;
      height: 130px;

      /* ECRAN RETINA */
      @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
      only screen and (-o-max-device-pixel-ratio: 3/2),
      only screen and (max--moz-device-pixel-ratio: 1.5),
      only screen and (max-device-pixel-ratio: 1.5) {
      	background: url('../images/logo-credit.png') center 50px no-repeat;
      }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
      only screen and (-o-min-device-pixel-ratio: 3/2),
      only screen and (min--moz-device-pixel-ratio: 1.5),
      only screen and (min-device-pixel-ratio: 1.5) {
      	background: url('../images/logo-credit@2x.png') center 50px no-repeat;
        background-size: 264px 64px;
      }
    }
  }

  #edit-commerce-payment-payment-method-payment-commerce-4commerce-payment-payment-commerce-4 {
    + label {
      background: url('../images/logo-virement.png') center 50px no-repeat;
      width: 136px;
      height: 130px;

      /* ECRAN RETINA */
      @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
      only screen and (-o-max-device-pixel-ratio: 3/2),
      only screen and (max--moz-device-pixel-ratio: 1.5),
      only screen and (max-device-pixel-ratio: 1.5) {
      	background: url('../images/logo-virement.png') center 50px no-repeat;
      }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
      only screen and (-o-min-device-pixel-ratio: 3/2),
      only screen and (min--moz-device-pixel-ratio: 1.5),
      only screen and (min-device-pixel-ratio: 1.5) {
      	background: url('../images/logo-virement@2x.png') center 50px no-repeat;
        background-size: 136px 55px;
      }
    }
  }
}
