.view-eshop {

  .view-footer {
    text-align: right;
    position: relative;

    .non-connect {
      display: none;

      .not-logged-in & {
        display: inline-block;
      }
    }

    .no-stock {
      @include rem(margin-bottom, 50px);
    }

    .flag-throbber {
      display: none;
    }

    .field-name-ds-flag-order {
      width: 100%;
      position: absolute;
      bottom: 0;

      @include xxs {
        top: 110%;
        bottom: auto;
      }
    }
  }

  &.view-display-id-page,
  &.view-display-id-block_5 {
    .view-content {
      display: flex;
      flex-wrap: wrap;

      .views-row {
        @include rem(padding-bottom, 15px);
        width: 30%;
        margin-bottom: 5%;
        box-shadow: 0px 0px 0px 1px $gray-lighter;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include xs {
          width: 100%;
        }

        &:nth-child(3n+2) {
          margin: 0 5% 5%;

          @include xs {
            margin-right: 0;
            margin-left: 0;
          }
        }

        &:hover,
        &:focus {

          .views-field-path {
            height: 70px;
          }

          .views-field-commerce-stock,
          .views-field-commerce-price,
          .views-field-field-dispo
           {
            opacity: 0;
          }

          .views-field-title-1,
          .views-field-title {
            transform: translateY(15px);
          }
        }

        .image-field-caption {
          display: none;
        }

        .views-field-field-images {

          img {
            display: block;
            width: 100%;
          }
        }

        .views-field-title-1,
        .views-field-title {
          @include rem(padding, 10px 10px 0);
          text-align: center;
          line-height: 1.2;
          transition: all linear .2s;
          flex: 1 1 auto;

          h3 {
            margin: 0;

            a {
              color: $black;
            }
          }
        }

        .views-field-commerce-stock,
        .views-field-field-dispo {
          @include rem(font-size, 12px);
          @include rem(margin-bottom, 10px);
          text-align: center;
          color: $primary-color;
          opacity: 1;
          transition: all linear .2s;

          .warning {
            color: $warning-color;
          }
        }

        .views-field-commerce-price {
          @include rem(font-size, 13px);
          text-align: center;
          color: $black;
          transition: all linear .2s;
          opacity: 1;

          .views-label {
            @include rem(font-size, 11px);
            color: $black-lighter;
          }
        }

        .views-field-path {
          height: 0;
          position: absolute;
          bottom: 0;
          width: 100%;
          overflow: hidden;
          transition: all linear .2s;

          a {
            @include rem(padding, 12px 0);
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;

            i {
              @include rem(margin-right, 5px);
            }
          }
        }
      }
    }
  }

  &.view-display-id-block_2,
  &.view-display-id-block_6 {
    position: relative;

    .views-field-edit-node {
      a {
        @extend %button;
        @extend %button-primary;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 500;

        &:before {
          @include custom-font("\EA04");
          @include rem(margin-right, 5px);
        }
      }
    }
  }

  &.view-display-id-block_3 {

    .grid-item {
      background: $gray-lightest;

      h3 {
        @include rem(padding, 0 20px);
        color: $primary-color;
        text-transform: uppercase;
        text-align: center;
      }

      .item-list {
        @include rem(padding, 0 20px 20px);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        li {
          @include rem(margin, 5px);
          padding: 0;
        }
      }

      ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;

        li {
          @include rem(margin-right, 5px);
          list-style: none;
        }
      }

      &.views-field-field-couleur-de-feuille {
        span[class^="color-"] {
          &:before {
            background: url('../images/color-feuille.png') no-repeat;

            /* ECRAN RETINA */
            @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
            only screen and (-o-max-device-pixel-ratio: 3/2),
            only screen and (max--moz-device-pixel-ratio: 1.5),
            only screen and (max-device-pixel-ratio: 1.5) {
            	background: url('../images/color-feuille.png') no-repeat;
            }
            @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
            only screen and (-o-min-device-pixel-ratio: 3/2),
            only screen and (min--moz-device-pixel-ratio: 1.5),
            only screen and (min-device-pixel-ratio: 1.5) {
            	background: url('../images/color-feuille@2x.png') no-repeat;
              background-size: 41px 41px;
            }
          }
        }
      }

      &.views-field-field-couleur-de-fleur {
        span[class^="color-"] {
          &:before {
            background: url('../images/color-fleur.png') no-repeat;

            /* ECRAN RETINA */
            @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
            only screen and (-o-max-device-pixel-ratio: 3/2),
            only screen and (max--moz-device-pixel-ratio: 1.5),
            only screen and (max-device-pixel-ratio: 1.5) {
            	background: url('../images/color-fleur.png') no-repeat;
            }
            @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
            only screen and (-o-min-device-pixel-ratio: 3/2),
            only screen and (min--moz-device-pixel-ratio: 1.5),
            only screen and (min-device-pixel-ratio: 1.5) {
            	background: url('../images/color-fleur@2x.png') no-repeat;
              background-size: 41px 41px;
            }
          }
        }
      }

      &.views-field-field-type-de-sol,
      &.views-field-field-climat,
      &[class^="views-field-field-hauteur-"] {

        ul {
          display: block;
          width: 100%;
        }
      }

      .field-hauteur {
        @include rem(margin, 0 20px 10px);
        @include rem(padding, 0 10px);
        height: 41px;
        background: $white;
        display: flex;
        align-items: center;

        &:before {
          @include custom-font('\EA29');
          @include rem(font-size, 32px);
          @include rem(margin-right, 10px);
          vertical-align: middle;
          width: 40px;
          text-align: center;
        }
      }

      .field-encombrement {
        @include rem(margin, 0 20px 10px);
        @include rem(padding, 0 10px);
        height: 41px;
        background: $white;
        display: flex;
        align-items: center;

        &:before {
          @include custom-font('\EA07');
          @include rem(font-size, 7px);
          @include rem(margin-right, 10px);
          vertical-align: middle;
          width: 40px;
          text-align: center;
        }
      }

      span[class^="tax-"] {
        font-size: 0;
        display: inline-block;
        width: 41px;
        height: 41px;
        text-align: center;
        line-height: 41px;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
          @include rem(font-size, 32px);
          @include custom-font('\EA0A');
        }
      }

      span[class^="color-"] {
        font-size: 0;
        display: inline-block;
        width: 39px;
        height: 39px;

        &:before {
          content:'';
          display: block;
          width: 41px;
          height: 41px;
          margin-top: -1px;
          margin-left: -1px;
        }


      }

      span[class^="sol-"] {
        @include rem(margin-bottom, 8px);
        @include rem(padding, 0 10px);
        height: 41px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: $white;

        &:before {
          @include rem(font-size, 9px);
          @include custom-font('\EA3F');
          display: block;
        }
      }

      span {
        display: block;

        &.item {
          @include rem(margin, 0 20px 10px);
          @include rem(padding, 0 10px);
          height: 41px;
          background: $white;
          display: flex;
          align-items: center;

          &:before {
            @include custom-font('\EA17');
            @include rem(font-size, 32px);
            @include rem(margin-right, 10px);
            vertical-align: middle;
            text-align: center;
            width: 40px;
            text-align: center;
          }

          &:last-child {
            @include rem(margin-bottom, 20px);
          }
        }

        &.sol-42 {
          color: $red-tax;
        }

        &.sol-43 {
          color: $purple;
        }

        &.sol-44 {
          color: $black;
        }

        &.color-15,
        &.color-31 {
          background: $silver;
        }

        &.color-207,
        &.color-19 {
          background: $white;
        }

        &.color-12,
        &.color-26 {
          background: $blue;
        }

        &.color-208,
        &.color-209 {
          background: $crema;
        }

        &.color-14,
        &.color-20 {
          background: $yellow;
        }

        &.color-17,
        &.color-30 {
          background: $black;
        }

        &.color-206,
        &.color-21 {
          background: $orange;
        }

        &.color-18 {
          @include background-gradient(48deg, ($green 0%, $green 49%, $crema 50%));
        }

        &.color-13,
        &.color-28 {
          background: $purpre;
        }

        &.color-212,
        &.color-23 {
          background: $pink;
        }

        &.color-16,
        &.color-22 {
          background: $red-tax;
        }

        &.color-11,
        &.color-27 {
          background: $green;
        }

        &.color-29 {
          background: $brown;
        }

        &.color-24 {
          background: $violet;
        }

        &.color-25 {
          background: $purple;
        }

        &.tax-1 {
          &:before {
            @include rem(font-size, 40px);
            content:"\EA0F";
          }
        }

        &.tax-2 {
          &:before {
            content:"\EA1A";
          }
        }

        &.tax-3 {
          &:before {
            content:"\EA46";
          }
        }

        &.tax-5 {
          &:before {
            content:"\EA26";
          }
        }

        &.tax-6 {
          &:before {
            content:"\EA11";
          }
        }

        &.tax-10 {
          &:before {
            content:"\EA32";
          }
        }

        &.tax-7 {
          &:before {
            content:"\EA15";
          }
        }

        &.tax-9 {
          &:before {
            content:"\EA36";
          }
        }

        &.tax-4 {
          &:before {
            content:"\EA27";
          }
        }

        &.tax-169 {
          &:before {
            content:"\EA16";
          }
        }

        &.tax-170 {
          &:before {
            content:"\EA35";
          }
        }

        &.tax-8 {
          &:before {
            content:"\EA3D";
          }
        }

        &.tax-203 {
          &:before {
            content: '\EA3B';
          }
        }

        &.tax-215 {
          &:before {
            content: '\EA20';
          }
        }

        &.tax-204 {
          &:before {
            content: '\EA10';
          }
        }

        &.tax-216 {
          &:before {
            content: '\EA2B';
          }
        }

        &.tax-171 {
          &:before {
            content: '\EA0D';
          }
        }

        &.tax-172 {
          &:before {
            content: '\EA0E';
          }
        }

        &.tax-173 {
          &:before {
            content: '\EA14';
          }
        }

        &.tax-174 {
          &:before {
            content: '\EA1F';
          }
        }

        &.tax-52 {
          &:before {
            content: '\EA45';
          }
        }

        &.tax-49 {
          &:before {
            content: '\EA40';
          }
        }

        &.tax-50 {
          &:before {
            content: '\EA30';
          }
        }

        &.tax-51 {
          &:before {
            content: '\EA34';
          }
        }

        &.tax-217 {
          &:before {
            content: '\EA08';
          }
        }

        &.tax-180 {
          &:before {
            content: '\EA12';
          }
        }

        &.tax-178 {
          &:before {
            content: '\EA28';
          }
        }

        &.tax-183 {
          &:before {
            content: '\EA2C';
          }
        }

        &.tax-175 {
          &:before {
            content: '\EA2D';
          }
        }

        &.tax-176 {
          &:before {
            @include rem(font-size, 28px);
            content: '\EA2E';
          }
        }

        &.tax-184 {
          &:before {
            @include rem(font-size, 12px);
            content: '\EA38';
          }
        }

        &.tax-185 {
          &:before {
            content: '\EA3A';
          }
        }

        &.tax-181 {
          &:before {
            content: '\EA3C';
          }
        }

        &.tax-182 {
          &:before {
            content: '\EA3E';
          }
        }

        &.tax-177 {
          &:before {
            content: '\EA41';
          }
        }

        &.tax-179 {
          &:before {
            content: '\EA42';
          }
        }

        &.tax-194 {
          &:before {
            content: '\EA09';
          }
        }

        &.tax-221 {
          &:before {
            content: '\EA0C';
          }
        }

        &.tax-188 {
          &:before {
            content: '\EA0B';
          }
        }

        &.tax-186 {
          &:before {
            content: '\EA0A';
          }
        }

        &.tax-191 {
          &:before {
            content: '\EA2A';
          }
        }

        &.tax-193 {
          &:before {
            content: '\EA44';
          }
        }

        &.tax-210 {
          &:before {
            content: '\EA24';
          }
        }

        &.tax-218 {
          &:before {
            @include rem(font-size, 14px);
            content: '\EA1C';
          }
        }

        &.tax-219 {
          &:before {
            content: '\EA1B';
          }
        }

        &.tax-189 {
          &:before {
            content: '\EA19';
          }
        }

        &.tax-199 {
          &:before {
            content: '\EA06';
          }
        }

        &.tax-196 {
          &:before {
            content: '\EA22';
          }
        }

        &.tax-197 {
          &:before {
            content: '\EA21';
          }
        }

        &.tax-195 {
          &:before {
            content: '\EA23';
          }
        }

        &.tax-198 {
          &:before {
            content: '\EA25';
          }
        }

        &.tax-211 {
          &:before {
            content: '\EA2F';
          }
        }

        &.tax-214 {
          &:before {
            content: '\EA33';
          }
        }

        &.tax-213 {
          &:before {
            content: '\EA31';
          }
        }

        &.tax-205 {
          &:before {
            content: '\EA37';
          }
        }

        &.tax-222 {
          &:before {
            content: '\EA13';
          }
        }

        &.tax-201 {
          &:before {
            content: '\EA18';
          }
        }

        &.tax-202 {
          &:before {
            @include rem(font-size, 14px);
            content: '\EA1D';
          }
        }

        &.tax-200 {
          &:before {
            content: '\EA39';
          }
        }

        &.tax-223 {
          &:before {
            content: '\EA43';
          }
        }

        &.tax-233 {
          &:before {
            content: '\EA41';
          }
        }
      }
    }
  }

  &.view-display-id-block_5,
  &.view-display-id-block_4,
  &.view-display-id-block_8 {
    .commerce-product-field-commerce-price {
      text-align: left;
      position: absolute;
      bottom: 0;

      .field-item {
        @include rem(font-size, 20px);
        font-weight: 600;
        color: $black;
      }
    }

    .form-submit {
      @include rem(padding, 13px 15px);
    }
  }

  &.view-display-id-block_5 {
    @include rem(margin-bottom, 50px);
    @include rem(margin-right, 25px);
    @include rem(margin-left, 25px);

    .slick-list {
      min-width: 100%;
    }

    .view-content {
      .views-row {
        box-shadow: none;
        padding-bottom: 0;
        margin-bottom: 0;

        &:nth-child(3n+2) {
          margin: 0;
        }

        .content {
          @include rem(padding-bottom, 15px);
          margin: 0 5%;
          box-shadow: 0px 0px 0px 1px $gray-lighter;
          border-bottom: 1px solid $gray-lighter;
          position: relative;
          background-color: $white;
        }
      }
    }
  }
}

#block-views-blog-block-1 {
  @include rem(margin-bottom, 30px);
  @include rem(padding, 15px 0);
  position: relative;
  overflow: hidden;

  &:before {
    background: rgba($black, 0.4);
    content:'';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }

  h2 {
    @include rem(padding, 20px 0);
    @include rem(font-size, 13px);
    text-align:center;
    color: $white;
    text-transform: uppercase;
    margin: 0;
    font-weight: 600;
    position: relative;
    z-index: 10;

    &:after {
      display: block;
      content: "";
      background: $white;
      height: 3px;
      width: 30px;
      margin: 10px auto 0;
    }
  }
  .views-field-field-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .views-field-title {
    @include rem(padding-top, 50px);
    position: relative;
    z-index: 15;
    @include xs {
      @include rem(padding-top, 20px);
    }

    h3 {
      @include rem(font-size, 38px);
      text-align: center;
      margin: 0;

      @include xs {
        @include rem(font-size, 27px);
      }
    }
  }

  .views-field-body {
    @include rem(padding-bottom, 70px);
    position: relative;
    z-index: 20;
    text-align: center;
    color: $white;

    @include xs {
      @include rem(padding-bottom, 50px);
    }

    .field-content {
      @extend %container;
    }

    p {
      margin: 0;
      text-align:center;
    }
  }

  .views-field-view-node {
    @include rem(padding-bottom, 30px);
    text-align: center;
    position: relative;
    z-index: 25;

    a {
      @extend %button;
      @extend %button-tertiary;
    }
  }
}

.view-users {
  .view-content {
    .views-row {
      .views-field {
        @include rem(margin-bottom, 40px);

        h4 {
          margin: 0;
          text-transform: uppercase;
        }

        h5 {
          @include rem(margin, 10px 0 5px);
        }

        p {
          @include rem(padding, 5px 10px);
          margin: 0;
          color: $black;
          border: 1px solid $gray-lighter;
        }
      }
      .views-field-field-prenom,
      .views-field-field-nom {
        width: 48%;
        float: left;
      }

      .views-field-field-prenom {
        margin-right: 4%;
      }

      .adr {
        clear: both;

        > div {
          overflow: hidden;

          .col-md-6 {
            width: 48%;
            float: left;

            &:first-child {
              margin-right: 4%;
            }
          }
        }
      }

      .views-field-edit-node{
        a {
          @extend %button;
          @extend %button-primary;
        }
      }
    }
  }
}

.view-commerce-user-orders {
  &.view-display-id-page_1 {

    .views-field-order-number {
      @include rem(padding, 7px 20px);
      @include rem(font-size, 15px);
      @include rem(margin-bottom, 15px);
      border: 1px solid $primary-color;
      background: $primary-color-lighter;
      color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      cursor: pointer;

      &:after {
        @include custom-font("\ea01");
        @include rem(font-size, 7px);
      }

      &.active {
        background: $primary-color;
        color: $white;

        &:after {
          content: "\ea02";
        }
      }
    }

    .views-field-nothing {
      @include rem(padding, 0 20px);
      display: none;
      border-bottom: 1px solid $primary-color;

      .details {
        @include rem(margin-bottom, 40px);
        color: $warning-color;

        .label-item {
          text-transform: uppercase;
          font-weight: 700;
          color: $black-lighter;
        }
      }

      .field-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        > .items {
          width: 100%;
        }

        .details {
          width: 100%;
        }
      }

      .total {
        @include rem(margin, 30px 0);
        width: 25%;

        table {

          tbody {
            border:none;
          }

          .component-title {
            color: $black-lighter;
          }

          .component-type-commerce-price-formatted-amount {
            td {
              @include rem(padding, 10px 0);
              font-weight: 700;
            }
          }
          td {
            padding: 0;
          }
        }
      }
    }
  }
}

.checkout-help {
  display: none;
}

#edit-checkout-review {

  tbody {
    border-top: none;
  }

  .pane-title {
    &:first-child {
      display: none;
    }
  }
}

#edit-cart-contents,
#edit-checkout-review {
  overflow: hidden;

  .pane-title {
    td {
      padding: 0;
    }
  }

  .pane-data-full {
    padding: 0;
  }

  .commerce-order-handler-area-order-total {
    width: 25%;
    float: right;

    @include xs {
      width: auto;
    }

    .commerce-price-formatted-components {
      @include rem(margin-top, 40px);
      width: 100%;

      tbody {
        border-top: none;

        td {
          padding: 0;
        }

        .component-type-taxtva {
          td {
            @include rem(padding-bottom, 10px);
          }
        }

        .component-type-commerce-price-formatted-amount {
          border-top: 1px solid $gray-lighter;

          td {
            @include rem(padding, 10px 0);
            @include rem(font-size, 18px);
          }
        }
      }
    }
  }
}

#commerce-checkout-coupon-ajax-wrapper {
  @include rem(padding, 25px 0);
  @include rem(margin, 50px 0);
  clear: both;
  border-top: 1px solid $gray-lighter;
  border-bottom: 1px solid $gray-lighter;

  @include xs {
    flex: 1 1 auto;
  }

  .form-item {
    margin: 0;

    @include xs {
      flex: 1 1 auto;
    }

    input {
      @include xs {
        width: 100%;
      }
    }
  }

  .commerce_coupon {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    .messages,
    .view-order-coupon-list {
      flex: 0 0 100%;
    }

    .view-order-coupon-list {
      @include rem(margin-top, 30px);
    }
  }

  label {
    @include rem(font-size, 18px);
    @include rem(margin-bottom, 10px);
    color: $black;
  }

  .description {
    display: none;
  }

  #edit-commerce-coupon-coupon-add {
    @include rem(padding, 7px 15px);
  }
}

#views-form-commerce-cart-form-default {

  .views-field-line-item-title {
    a {
      color: $black;
      font-weight: 600;
    }
  }

  tbody {
    .views-field-edit-delete {
      position: relative;

      &:hover {
        &:before {
          color: $primary-color;
        }
      }

      &:before {
        @include custom-font("\EA4E");
        pointer-events: none;
        position: absolute;
        right: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
      }

      input {
        text-indent: -99999px;
        float: right;
        display: block;
        background: none;
        width: 100%;
        min-width: 40px;
        height: 40px;
      }
    }
  }

  .line-item-summary {
    @include rem(margin, 30px 0);
    width: 25%;
    float: right;
    text-align: left;
    color: $black;

    .line-item-total-label {
      @include rem(font-size, 14px);
      font-weight: normal;
      display: block;
      border-bottom: 1px solid $gray-lighter;
      text-transform: uppercase;
    }

    .line-item-total-raw {
      @include rem(font-size, 18px);
      @include rem(padding, 10px 0);
      font-weight: 600;
      display: block;
    }
  }

  .form-actions {
    clear: both;

    &:before {
      @include custom-font("\EA05");
      width: 42px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      position: absolute;
      pointer-events: none;
      color: $black;
    }
    #edit-submit {
      @include rem(margin-right, 10px);
      display: inline-block;
      width: 42px;
      height: 42px;
      text-indent: -9999px;
      background:none;
      color: $black;

    }
  }
}

.view-commerce-wishlist-page {
  .views-field-wishlist-remove {
    text-align: right;

    a {
      @include rem(padding-left, 20px);
      display: inline-block;
      font-size: 0;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:before {
         @include custom-font("\EA4E");
         @include rem(font-size, 15px);
         @include rem(margin-right, 10px);
      }
    }
  }

  .views-field-line-item-title {
    a {
      color: $black;
      font-weight: 600;
    }
  }
}

.view-agenda {
  &.view-display-id-page,
  &.view-display-id-page_1 {
    .slick-list {
      @include rem(margin, 0 -15px);

      .slick-slide {
        @include rem(margin, 0 15px);
      }
    }

    .view-content {
      overflow: hidden;
    }

    .views-row {
      width: 31%;
      text-align: center;
      border-bottom: 1px solid $gray-lighter;
      float: left;
      margin-bottom: 3.5%;

      &:nth-child(3n+1) {
        clear: both;

        @include sm {
          clear: none;
        }
      }

      &:nth-child(2n+1) {
        @include sm {
          margin-right: 4% !important;
          clear: left;
        }
      }

      &:nth-child(3n+2) {
        margin-left: 3.5%;
        margin-right: 3.5%;

        @include xssm {
          margin-left: 0;
          margin-right: 0;
        }
      }

      @include sm {
        width: 48%;
      }

      @include xs {
        width: 100%;
      }

      .views-field-field-image {
        img {
          display: block;
          width: 100%;
        }
      }

      .views-field-field-fin,
      .views-field-field-fin-1,
      .views-field-title,
      .views-field-field-site-web,
      .views-field-field-presse {
        border-left: 1px solid $gray-lighter;
        border-right: 1px solid $gray-lighter;
      }

      .views-field-field-fin {
        @include rem(padding, 20px 20px 0);
        color: $primary-color-darker;
        font-weight: bold;
      }

      .views-field-field-fin-1 {
        @include rem(padding, 0 20px);
      }

      .views-field-title {
        @include rem(padding, 20px);

        h3 {
          margin: 0;

          a {
            color: $black;

            &:hover,
            &:focus {
              color: $primary-color;
              text-decoration: none;
            }
          }
        }
      }

      .views-field-field-site-web {
        @include rem (padding-bottom, 20px);

        &:before {
          @include rem(margin, 0 auto 20px);
          content:"";
          display: block;
          height: 1px;
          width: 40px;
          background: $gray-lighter;
        }
      }

      .views-field-field-presse {
        @include rem (padding-bottom, 20px);
      }
    }
  }

  &.view-display-id-block_1,
  &.view-display-id-block_2 {
    .view-content {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .views-row {
      @extend %container;
    }
  }
}

.page-blog {
  .view-Blog {
    .view-filters {
      @extend %container;
      display: flex;
      justify-content: flex-start;
      margin: 0;
      width: 100%;

      @include lg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        box-sizing: border-box;
        padding-left: 300px;
        justify-content: flex-end;
        margin: 0 auto;
      }

      @include xssmmd {
        @include rem(margin-bottom, 20px);
        position: relative;
        flex-wrap: wrap;
        transform: none;
        padding-left: 0;
        left: auto;
        max-width: none;
        width: 100%;
      }

      .views-exposed-widget {
        @include rem(margin-top, 8px);
        display: flex;
        padding: 0;

        @include xs {
          display: block;
        }

        label {
          font-weight: normal;
          color: $black;
        }
      }

      .views-widget {
        .form-item {
          display: flex;

          @include xs {
            flex-wrap: wrap;
          }

          a {
            @include rem(margin-left, 20px);
            color: $black;

            @include xs {
                @include rem(margin-right, 20px);
                margin-left: 0;
            }

            &.active,
            &:hover,
            &:focus {
              color: $primary-color;
              text-decoration: underline;
            }
          }
        }
      }
    }

    &.view-display-id-page {
      > .view-content {
        display: flex;
        flex-wrap: wrap;

        @include xs {
          display: block;
        }

        .views-row {
          width: 32%;
          margin-bottom: 2%;
          box-shadow: 0 0 1px 0 $gray;

          @include xs {
            width: 100%;
          }

          &:nth-child(3n+2) {
            margin-left: 2%;
            margin-right: 2%;

            @include xs {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }

        .views-field-field-image {
          img {
            display: block;
            width: 100%;
          }
        }

        .views-field-title,
        .views-field-body,
        .views-field-field-nom,
        .views-field-created {
          @include rem(padding, 0 20px);
        }

        .views-field-title {
          @include rem(padding-top, 20px);
          h3 {
            margin: 0;

            a {
              color: $black;

              &:hover,
              &:focus {
                color: $primary-color;
                text-decoration: none;
              }
            }
          }
        }

        .views-field-body {
          @include rem(padding-bottom, 20px);
          p {
            margin: 0;
          }
        }

        .views-field-field-nom {
          @include rem(font-size, 12px);
          color: $gray;

          &:before {
            @include rem(margin-bottom, 10px);
            content:"";
            display: block;
            background: $gray;
            width: 30px;
            height: 1px;
          }
        }

        .views-field-created {
          @include rem(padding-bottom, 20px);
          @include rem(font-size, 12px);
          color: $gray;
        }
      }
    }
  }
}

.view-faq {

  > .view-content {
    display: flex;
    flex-wrap: wrap;
  }

  .views-row {
    width: 100%;
    margin-bottom: 3.5%;
    border: 1px solid $gray-lighter;
    display: flex;

    @include xs {
      display: block;
    }
  }

  .views-field-field-image {
    img {
      display: block;
      width: auto;
      max-width: none;
      height: 100%;

      @include xs {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }

    .field-content,
    a {
      display: block;
      height: 100%;
    }
  }

  .views-field-nothing {
    @include rem(padding, 20px);

    .field-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  h2 {
    @include rem(padding-top, 20px);

    margin: 0;
    a {
      color: $black;
    }
  }
}

#edit-field-synonymes-tid-wrapper,
#edit-field-synonymes-tid-i18n-wrapper {
  display: none;
}
