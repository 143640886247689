%headings-1-to-6-defaults {
  color: $headings-color;
  @include rem( margin-bottom, $headings-margins);
  font: {
    family: $headings-font-family;
    weight: $headings-font-weight;
  }
}

/* -----------------------------
 * GRID
 * ----------------------------*/
%container {
  width: 90%;
  @include rem(max-width, $container-width);
  margin: {
    right: auto;
    left: auto;
  }
}

/* -----------------------------
 * FORMS
 * ----------------------------*/
%form-item {
  @include rem(padding, 10px);
  box-sizing: border-box;
  appearance: none;
  display: block;
  width: 100%;
  border-radius: 2px;
  background: {
    image: none;
  }
  border: 1px solid $gray-darker;

  &:focus {
    outline: none;
    border-color: darken($gray-darker, 10%);
  }

  &::placeholder {
    color: $gray-darker;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    background-color: darken($gray-lighter, 5%);
    opacity: 1;
  }

  &:disabled {
    color: $gray;
    cursor: pointer;
  }

  &.error {
    border: {
      color: $red;
    }

    + .description {
      color: $red;
    }
  }
}


/* ---------------------------
 * BUTTONS
 * --------------------------*/

%button {
  display: inline-block;
  text-align: center;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

%button-primary {
  @include rem(padding, 5px 10px);
  background: $primary-color-lighter;
  color: $primary-color-darker;

  &:hover,
  &:focus,
  &.active,
  &.active-trail {
    background: $primary-color;
    color: $white;
  }
}

%button-encyclo {
  @include rem(padding, 5px 10px);
  background: $secondary-color;
  color: $white;

  &:hover,
  &:focus,
  &.active,
  &.active-trail {
    background: $secondary-color-darker;
    color: $white;
  }
}

%button-secondary {
  @include rem(padding, 5px 15px);
  background: $primary-color;
  color: $white;
  text-transform: uppercase;

  &:hover,
  &:focus {
    background: $primary-color-darker;
  }
}

%button-tertiary {
  @include rem(font-size, 12px);
  @include rem(padding, 2px 15px);
  background: $white;
  color: $primary-color;
  text-transform: uppercase;
  border: 2px solid $white;
  border-radius: 15px;

  &:hover,
  &:focus {
    border-color: $primary-color;
    background: none;
  }
}

%button-warning {
  @include rem(padding, 5px 10px);
  background: $warning-color;
  color: $white;
  font-weight: 400;

  &:hover,
  &:focus {
    background: darken($warning-color, 10%);
  }
}

%button-outline {
  @include rem(padding, 5px 10px);
  @include rem(font-size, 12px);
  // border-radius: 12px;
  border: 1px solid $primary-color-darker;
  color: $primary-color-darker;
  background: none;

  &:hover,
  &:focus {
    background: $primary-color-darker;
    color: $white;
  }
}
