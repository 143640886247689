.field-type-faqfield {
  h3 {
    @include rem(padding, 0 30px 10px 0);

    a {
      @include rem(font-size, 15px);
      color: $black;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:after {
        @include rem(margin-left, 10px);
        display: inline-block;
        content:"";
        width: 7px;
        height: 7px;
        background: url('../images/arrow-bottom.png') no-repeat;

        /* ECRAN RETINA */
        @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
        only screen and (-o-max-device-pixel-ratio: 3/2),
        only screen and (max--moz-device-pixel-ratio: 1.5),
        only screen and (max-device-pixel-ratio: 1.5) {
        	background: url('../images/arrow-bottom.png') no-repeat;
        }
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (-o-min-device-pixel-ratio: 3/2),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
          background: url('../images/arrow-bottom@2x.png') no-repeat;
          background-size: 7px 7px;
        }
      }
    }

    &.ui-accordion-header-active {
      a {
        &:after {
          background: url('../images/arrow-top.png') no-repeat;
          /* ECRAN RETINA */
          @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
          only screen and (-o-max-device-pixel-ratio: 3/2),
          only screen and (max--moz-device-pixel-ratio: 1.5),
          only screen and (max-device-pixel-ratio: 1.5) {
          	background: url('../images/arrow-top.png') no-repeat;
          }
          @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
          only screen and (-o-min-device-pixel-ratio: 3/2),
          only screen and (min--moz-device-pixel-ratio: 1.5),
          only screen and (min-device-pixel-ratio: 1.5) {
            background: url('../images/arrow-top@2x.png') no-repeat;
            background-size: 7px 7px;
          }
        }
      }
    }
  }

  .faqfield-answer {
    &:after {
      @include rem(margin-bottom, 20px);
      display: block;
      content:"";
      width: 50px;
      background: $gray;
      height: 1px;
    }
  }
}

.node-faq {
  .field-name-body {
    @include rem(margin-bottom, 60px);
  }
}

ul.inline li.comment-add {
  display: none;
}

.back-link {
  color: $black;
  position: absolute;
  z-index: 300;

  &:before {
    @include rem(margin-right, 10px);
    @include custom-font('\EA47');
    @include rem(font-size, 8px);
    display: inline-block;
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    color: $primary-color;
  }
}

.field-name-field-synonymes {
  .field-items {
    display: flex;
  }

  .field-item {
    &:after {
      content:',';
      margin-right: 5px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.views-field-field-images {
  position: relative;

  .icon-bio {
    background: white url('../images/biodiversite.png') center center no-repeat;
    background-size: 100% auto;
    bottom: 0;
    display: block;
    height: 50px;
    position: absolute;
    right: 0;
    width: 50px;
  }
}

.field-name-field-biodiversit- {
  .icon-bio {
    background: white url('../images/biodiversite.png') center center no-repeat;
    background-size: 100% auto;
    display: block;
    height: 50px;
    width: 50px;
  }
}