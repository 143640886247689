*:focus,
*:active {
  outline: none;
  box-shadow: none;
}
body {
  @include rem(font-size, 14px);
  font-family: $global-font-family;
  line-height: $global-line-height;
  color: $black-lighter;
}

h1 {
  @extend %headings-1-to-6-defaults;
  font: {
    @include rem(size, $headings-1-font-size);
    weight: 800;
  }
}

h2 {
  @extend %headings-1-to-6-defaults;
  font: {
    @include rem(size, $headings-2-font-size);
  }
}

h3 {
  @extend %headings-1-to-6-defaults;
  font: {
    @include rem(size, $headings-3-font-size);
    weight: 600;
  }
}

h4 {
  @extend %headings-1-to-6-defaults;
  font: {
    @include rem(size, $headings-4-font-size);
  }
}

h5 {
  @extend %headings-1-to-6-defaults;
  font: {
    @include rem(size, $headings-5-font-size);
  }
  color: $black-lighter;
}

h6 {
  @extend %headings-1-to-6-defaults;
  font: {
    @include rem(size, $headings-6-font-size);
  }
}

img {
  max-width: 100%;
  height: auto;
}

p {
  &.copyright {
    color: $gray;
  }
}

table {
  width: 100%;
  .views-field-commerce-total {
    width: 25%;
  }
  thead {
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;

    th {
      @include rem(padding, 10px 0);
      text-transform: uppercase;
      border: none;
      font-weight: 400;
    }
  }

  tbody {
    tr {
      &.odd,
      &.even {
        background: none;
        border:none;
        vertical-align: middle;
      }
    }
    td {
      @include rem(padding, 10px 0);
      border: none;
      background: none;
      color: $black;
    }
  }
}

a {
  color: $primary-color;
  text-decoration: none;
  transition: all linear .2s;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

span{
  &.title {
    text-transform: uppercase;
  }
}

label {
  font-weight: 400;
}

input {
  @include rem(padding, 5px 10px);
}

#messages {
  .front & {
    @include rem(padding-top, 30px);
    background: $gray-lightest;
  }
}
div.messages {
  @include rem(padding, 15px 20px);
  @include rem(margin, 10px 0 20px);
  display: block;

  .front & {
    @extend %container;
    @include rem(margin-bottom, 100px);
    @include rem(margin-top, -40px);
  }

  &.status {
    background: $success-color;
    border: 1px solid darken($success-color, 10%);

    &:before {
      @include custom-font("\EA4B");
      @include rem(margin-right, 10px);
      display: inline-block;
    }
  }

  &.warning {
    background: $warning-color;
    border: 1px solid darken($warning-color, 10%);

    &:before {
      @include custom-font("\EA4B");
    }
  }

  &.error {
    background: $error-color;
    border: 1px solid darken($error-color, 10%);

    &:before {
      @include custom-font("\EA4F");
      @include rem(margin-right, 10px);
      font-weight: 900;
    }
  }

  a {
    color: $black;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

strong {
  color: $black;
}

.highlighted {
  @include rem(padding, 20px);
  background: $primary-color-lighter;
  border: 1px dashed $primary-color;
  color: $primary-color-darker;
}

.btn-warning {
  @extend %button;
  @extend %button-warning;
}

span {
  &.hidden {
    display: none;
  }

  &.warning {
    color: $warning-color;
    font-weight: 700;
  }
}
