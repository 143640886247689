#prefooter {
  @include rem(margin-top, 70px);
  background: $primary-color;

  @include xs {
    @include rem(padding, 20px 0);
    overflow:hidden;

    .webform-component--email {
      margin: 0;
    }

    input#edit-submitted-email {
      float: left;
      width: 200px;
      margin-top: 10px;
    }

    .form-actions {
      margin: 0;
      margin-top: 10px;
    }
  }

  .node-type-article & {
    margin-top: 0;
  }
  .description {
    @include rem(font-size, 15px);
    @include rem(margin-right, 30px);
    color: $white;
    font-weight: 600;
  }

  form {

    > div {
      display: flex;
      justify-content: center;
      align-items: center;

      @include xs {
        display: block;
      }
    }

    .webform-component {
      display: flex;
      align-items: center;

      @include xs {
        display: block;
      }

      input {
        @include rem(padding, 7px);
        border: none;
      }
    }

    .webform-submit {
      @include rem(padding, 7px);
      background: $primary-color-darker;
      color: $primary-color-lighter;
      border: none;
    }
  }
}

footer {
  background: $white;

  .block-menu {
    ul.menu {
      padding: 0;
      margin: 0;

      li {
        padding: 0;
        margin: 0;
        list-style: none;

        a {
          color: $black;

          &:hover,
          &:focus {
            color: $primary-color;
          }
        }
      }
    }
  }

  .region-footer {
    @include rem(padding, 30px 0);
    display: flex;
    justify-content: space-between;

    @include xs {
      display: block;
    }
  }

  h2 {
    margin: 0;
    @include rem(margin-bottom, 20px);
    font-weight: 400;
  }

  .views-field-street {
    @include rem(margin-bottom, 10px);
  }

  #block-views-contact-block-3 {
    .views-field {
      @include rem(font-size, 18px);
      display: inline-block;
      a {
        display: block;
        padding: 5px;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
      &.views-field-field-facebook {
        a {
          color: $fb-color;
        }
      }

      &.views-field-field-youtube {
        a {
          color: $youtube-color;
        }
      }

      &.views-field-field-instagram {
        a {
          color: $insta-color;
          position: relative;

          &:after {
            @include background-gradient(135deg, (rgba($white, 0) 0%, rgba($white,1) 80%));
            mix-blend-mode: overlay;
            display: block;
            content:"";
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom:0;
            pointer-events: none;
          }
        }
      }
    }
  }

  .copyright {
    @include rem(font-size, 12px);
    @include rem(padding, 5px 0);
    background: $primary-color-lighter;
    color: $primary-color-darker;

    .container {
      display: flex;
      justify-content: space-between;

      @include xs {
        display: block;
      }

      p {
        margin: 0;
        padding: 0;
      }
    }
  }
}
