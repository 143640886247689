#block-easy-breadcrumb-easy-breadcrumb {

  h2 {
    @include rem(font-size, 12px);
    color: $black-lighter;
    font-style: italic;
    display: inline-block;
  }

  .content {
    display: inline-block;

    .easy-breadcrumb {
      span {
        @include rem(font-size, 14px);
        color: $black-lighter;

        a {
          color: $primary-color-darker;
          text-decoration: none;
        }
      }
    }
  }
}

#block-views-exp-eshop-page,
#block-views-exp-encyclop-die-page {
  position: relative;

  h2 {
    @include rem(font-size, 21px);
    color: $black;
    border-bottom: 1px solid $black-lighter;
  }

  .views-reset-button {
    position: absolute;
    top: 5px;
    right: 0;
    padding: 0;

    input {
      @include rem(font-size, 12px);
      margin: 0;
      background: none;
      border: 0;
      color: $primary-color;
    }
  }

  .views-submit-button {
    position: absolute;
    top: 77px;
    right: 15px;
    padding: 0;

    &:after {
      @include custom-font('\EA60');
      color: $white;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      width: 30px;
      height: 29px;
      line-height: 30px;
      text-align: center;
      pointer-events: none;
    }

    input {
      background: $primary-color;
      margin-top: 0;
      font-size: 0;
      width: 30px;
      height: 28px;

      &:hover,
      &:focus {
        background: $primary-color-darker;
      }
    }
  }

  .views-exposed-widget {
    @include rem(margin-bottom, 30px);
    float: none;

    &.views-widget-filter-title_1 {
      input {
        width: 100%;
        box-sizing: border-box;
      }
    }

    > label {
      text-transform: uppercase;
      font-weight: bold;
      color: $black;
    }

    .views-widget {
      label {
        font-weight: normal;
      }
    }
    &#edit-title-wrapper{

      .bef-select-as-links {

        > .form-item {
          display: flex;
          flex-wrap: wrap;

          a {
            @include rem(margin, 5px);
            @include rem(width, 30px);
            @include rem(height, 30px);
            text-align: center;
            line-height: 32px;
            display: block;
            background: $primary-color-lighter;

            &:hover,
            &:focus,
            &.active {
              background: $primary-color;
              color: $white;
              text-decoration: none;
            }
          }

          .form-item-edit-title-all {
            a {
              @include rem(width, 70px);
            }
          }
        }
      }
    }

    &#edit-field-couleur-de-fleur-tid-wrapper,
    &#edit-field-couleur-de-feuille-tid-wrapper {
      .views-widget {
        input {
          display: none;
        }

        .form-item {
          @include rem(margin-right, 5px);
          display: inline-block;
          vertical-align: middle;

          input:checked + label {
            box-shadow: 0px 0px 0px 2px $black;
          }

          label {
            font-size: 0;
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }
    }
    &#edit-field-couleur-de-feuille-tid-wrapper {
      .views-widget {

        .form-item {

          &.form-item-edit-field-couleur-de-feuille-tid-15 {
            label {
              background: $silver;
              box-shadow: 0px 0px 0px 2px $silver;
            }
          }

          &.form-item-edit-field-couleur-de-feuille-tid-207 {
            label {
              box-shadow: 0px 0px 0px 2px $gray-lighter;
            }
          }

          &.form-item-edit-field-couleur-de-feuille-tid-12 {
            label {
              background: $blue;
              box-shadow: 0px 0px 0px 2px $blue;
            }
          }

          &.form-item-edit-field-couleur-de-feuille-tid-208 {
            label {
              background: $crema;
              box-shadow: 0px 0px 0px 2px $crema;
            }
          }

          &.form-item-edit-field-couleur-de-feuille-tid-231 {
            label {
              background: $brown;
              box-shadow: 0px 0px 0px 2px $brown;
            }
          }

          &.form-item-edit-field-couleur-de-feuille-tid-14 {
            label {
              background: $yellow;
              box-shadow: 0px 0px 0px 2px $yellow;
            }
          }

          &.form-item-edit-field-couleur-de-feuille-tid-17 {
            label {
              background: $black-tax;
              box-shadow: 0px 0px 0px 2px $black-tax;
            }
          }

          &.form-item-edit-field-couleur-de-feuille-tid-206 {
            label {
              background: $orange;
              box-shadow: 0px 0px 0px 2px $orange;
            }
          }

          &.form-item-edit-field-couleur-de-feuille-tid-18 {
            label {
              @include background-gradient(48deg, ($green 0%, $green 49%, $crema 50%));
              width: 19px;
              height: 19px;
            }
          }

          &.form-item-edit-field-couleur-de-feuille-tid-13 {
            label {
              background: $purpre;
              box-shadow: 0px 0px 0px 2px $purpre;
            }
          }

          &.form-item-edit-field-couleur-de-feuille-tid-212 {
            label {
              background: $pink;
              box-shadow: 0px 0px 0px 2px $pink;
            }
          }

          &.form-item-edit-field-couleur-de-feuille-tid-16 {
            label {
              background: $red-tax;
              box-shadow: 0px 0px 0px 2px $red-tax;
            }
          }

          &.form-item-edit-field-couleur-de-feuille-tid-11 {
            label {
              background: $green;
              box-shadow: 0px 0px 0px 2px $green;
            }
          }
        }

        #edit-field-couleur-de-feuille-tid-all {

          + label {
            @extend %button;
            @extend %button-primary;
            color: $primary-color;
          }
        }
      }
    }

    &#edit-field-couleur-de-fleur-tid-wrapper {
      .views-widget {

        .form-item {

          &.form-item-edit-field-couleur-de-fleur-tid-19 {
            label {
              box-shadow: 0px 0px 0px 2px $gray-lighter;
            }
          }

          &.form-item-edit-field-couleur-de-fleur-tid-26 {
            label {
              background: $blue;
              box-shadow: 0px 0px 0px 2px $blue;
            }
          }

          &.form-item-edit-field-couleur-de-fleur-tid-29 {
            label {
              background: $brown;
              box-shadow: 0px 0px 0px 2px $brown;
            }
          }

          &.form-item-edit-field-couleur-de-fleur-tid-209 {
            label {
              background: $crema;
              box-shadow: 0px 0px 0px 2px $crema;
            }
          }

          &.form-item-edit-field-couleur-de-fleur-tid-31 {
            label {
              background: $gray;
              box-shadow: 0px 0px 0px 2px $gray;
            }
          }

          &.form-item-edit-field-couleur-de-fleur-tid-20 {
            label {
              background: $yellow;
              box-shadow: 0px 0px 0px 2px $yellow;
            }
          }

          &.form-item-edit-field-couleur-de-fleur-tid-25 {
            label {
              background: $purple;
              box-shadow: 0px 0px 0px 2px $purple;
            }
          }

          &.form-item-edit-field-couleur-de-fleur-tid-30 {
            label {
              background: $black-tax;
              box-shadow: 0px 0px 0px 2px $black-tax;
            }
          }

          &.form-item-edit-field-couleur-de-fleur-tid-21 {
            label {
              background: $orange;
              box-shadow: 0px 0px 0px 2px $orange;
            }
          }

          &.form-item-edit-field-couleur-de-fleur-tid-28 {
            label {
              background: $purpre;
              box-shadow: 0px 0px 0px 2px $purpre;
            }
          }

          &.form-item-edit-field-couleur-de-fleur-tid-23 {
            label {
              background: $pink;
              box-shadow: 0px 0px 0px 2px $pink;
            }
          }

          &.form-item-edit-field-couleur-de-fleur-tid-22 {
            label {
              background: $red-tax;
              box-shadow: 0px 0px 0px 2px $red-tax;
            }
          }

          &.form-item-edit-field-couleur-de-fleur-tid-27 {
            label {
              background: $green;
              box-shadow: 0px 0px 0px 2px $green;
            }
          }

          &.form-item-edit-field-couleur-de-fleur-tid-24 {
            label {
              background: $violet;
              box-shadow: 0px 0px 0px 2px $violet;
            }
          }
        }
      }
    }
  }
}

.item-list {

  .pager {
    text-align: right;

    li {
      margin: 0;
      padding: 0;

      &.pager-current {
        color: $primary-color-darker;
      }

      &.pager-first {
        a {
          font-size: 0;
          border: 1px solid $primary-color-darker;
          display: inline-block;
          border-radius: 50%;
          color: $primary-color-darker;
          width: 25px;
          height: 25px;
          line-height: 25px;
          vertical-align: middle;
          text-align: center;

          &:before {
            @include custom-font("\EA50");
            @include rem(font-size, 12px);
            vertical-align: middle;
          }

          &:hover,
          &:focus {
            text-decoration: none;
            background: $primary-color-darker;
            color: $white;
          }
        }
      }

      &.pager-previous {
        a {
          @include rem(margin-right, 10px);
          font-size: 0;
          border: 1px solid $primary-color-darker;
          display: inline-block;
          border-radius: 50%;
          color: $primary-color-darker;
          width: 25px;
          height: 25px;
          line-height: 25px;
          vertical-align: middle;
          text-align: center;

          &:before {
            @include custom-font("\EA53");
            @include rem(font-size, 12px);
            vertical-align: middle;
          }

          &:hover,
          &:focus {
            text-decoration: none;
            background: $primary-color-darker;
            color: $white;
          }
        }
      }

      &.pager-next {
        a {
          @include rem(margin-left, 10px);
          font-size: 0;
          border: 1px solid $primary-color-darker;
          display: inline-block;
          border-radius: 50%;
          color: $primary-color-darker;
          width: 25px;
          height: 25px;
          line-height: 25px;
          vertical-align: middle;
          text-align: center;

          &:before {
            @include custom-font("\EA52");
            @include rem(font-size, 12px);
            vertical-align: middle;
          }

          &:hover,
          &:focus {
            text-decoration: none;
            background: $primary-color-darker;
            color: $white;
          }
        }
      }

      &.pager-last {
        a {
          font-size: 0;
          border: 1px solid $primary-color-darker;
          display: inline-block;
          border-radius: 50%;
          color: $primary-color-darker;
          width: 25px;
          height: 25px;
          line-height: 25px;
          vertical-align: middle;
          text-align: center;

          &:before {
            @include custom-font("\EA51");
            @include rem(font-size, 12px);
            vertical-align: middle;
          }

          &:hover,
          &:focus {
            text-decoration: none;
            background: $primary-color-darker;
            color: $white;
          }
        }
      }

      a {
        color: $black;
        text-decoration: none;

        &:hover,
        &:focus {
          color: $primary-color-darker;
          text-decoration: underline;
        }
      }
    }
  }
}

#block-views-contact-block {
  width: 30%;

  @include xs {
    @include rem(margin-bottom, 20px);
    width: 100%;
  }

  > h2 {
    @include rem(font-size, 24px);
  }

  .views-field-street {
    @include rem(margin-bottom, 20px);
  }

  .views-field-nothing {
    @include rem(margin-top, 50px);

    .btn {
      @include rem(margin-top, 10px);
    }
  }
}

#block-views-contact-block-1 {
  width: 70%;

  @include xs {
    width: 100%;
  }
}

.cart-image, .cart-product-quantity, .cart-product-total {
  float: none;
}

#block-commerce-cart-cart {
  @include rem(margin-bottom, 20px);
  position: relative;

  &:hover {
    cursor: pointer;
  }
  .cart-empty-block {
    float: none;
    @include rem(font-size, 10px);
    text-align: center;
    width: 30px;
    height: 29px;
    background: url('../images/icon-cart.png') no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 10px;

    /* ECRAN RETINA */
    @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
    only screen and (-o-max-device-pixel-ratio: 3/2),
    only screen and (max--moz-device-pixel-ratio: 1.5),
    only screen and (max-device-pixel-ratio: 1.5) {
    	background: url('../images/icon-cart.png') no-repeat;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
      background: url('../images/icon-cart@2x.png') no-repeat;
      background-size: 30px 29px;
    }
  }
  .view-commerce-cart-block {

    > .view-header {

      .line-item-summary {
        @include rem(margin-top, 10px);
        margin-bottom: 0;

        .line-item-quantity {
          float: none;
          @include rem(font-size, 10px);
          text-align: center;
          width: 30px;
          height: 29px;
          background: url('../images/icon-cart.png') no-repeat;
          display: flex;
          align-items: flex-end;
          justify-content: center;

          /* ECRAN RETINA */
          @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
          only screen and (-o-max-device-pixel-ratio: 3/2),
          only screen and (max--moz-device-pixel-ratio: 1.5),
          only screen and (max-device-pixel-ratio: 1.5) {
          	background: url('../images/icon-cart.png') no-repeat;
          }
          @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
          only screen and (-o-min-device-pixel-ratio: 3/2),
          only screen and (min--moz-device-pixel-ratio: 1.5),
          only screen and (min-device-pixel-ratio: 1.5) {
            background: url('../images/icon-cart@2x.png') no-repeat;
            background-size: 30px 29px;
          }

          .line-item-quantity-label {
            display: none;
          }
        }
      }
    }

    > .view-content {
      @include rem(padding, 20px);
      @include rem(width, 300px);
      @include rem(margin-top, 20px);
      position: absolute;
      right: 0;
      background: $white;
      box-shadow: 0 0 5px $gray-lighter;
      z-index: 200;
      display: none;

      &:before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white;
        display: block;
        content:"";
        position: absolute;
        top: -10px;
        right: 5px;
      }

      table {
        tbody {
          border: none;
        }
      }
      .line-item-summary {

        .links {
          @include rem(margin-top, 30px);
          display: block;
        }

        .line-item-summary-view-cart {
          a {
            @extend %button;
            @extend %button-primary;
            @include rem(margin-bottom, 10px);
          }
        }

        .line-item-summary-checkout {
          a {
            @extend %button;
            @extend %button-secondary;
            @include rem(padding, 5px 10px);
            text-transform: none;
          }
        }
      }
    }
  }
}

#block-dc-ajax-add-cart-ajax-shopping-cart-teaser {
  @include rem(margin-bottom, 20px);

  .cart-product-quantity {
    @include rem(font-size, 10px);
    text-align: center;
    width: 30px;
    height: 29px;
    background: url('../images/icon-cart.png') no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    /* ECRAN RETINA */
    @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
    only screen and (-o-max-device-pixel-ratio: 3/2),
    only screen and (max--moz-device-pixel-ratio: 1.5),
    only screen and (max-device-pixel-ratio: 1.5) {
    	background: url('../images/icon-cart.png') no-repeat;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
      background: url('../images/icon-cart@2x.png') no-repeat;
      background-size: 30px 29px;
    }

    p {
      margin: 0;
      @include rem(margin-bottom, 1px);
    }
  }
}

.slider {
  .views-field-field-images {
    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }
  }
}

#block-views-eshop-block-1 {

  @include xssm {
    margin-bottom: 60px;
  }

  h1 {
    @include rem(margin-bottom, 5px);
    font-weight: 700;
  }

  .views-field-field-famille {
    @include rem(margin-bottom, 20px);
  }

  .views-field-body {
    &:before {
      display: block;
      content: "";
      height: 1px;
      width: 150px;
      background: $gray-lighter;
    }
  }

  .view-footer {
    .node-plantes {
      min-height: 41px;
    }
  }

  .views-field-commerce-price {
    @include rem(font-size, 20px);
    font-weight: 600;
    color: $black;
  }

  .views-field-add-to-cart-form {
    .form-item-quantity {
      label {
        display: none;
      }
    }
    #edit-submit {
      @extend %button;
      @extend %button-secondary;
      border:none;
    }
  }

  .flag-order {
    a {
      @extend %button;
      @extend %button-warning;

      @include xssm {
        box-sizing: border-box;
        width: 100%;
      }

      &.unflag-action {
        background: $warning-color;

        &:hover,
        &:focus {
          background: darken($warning-color, 10%);
        }
      }
    }
  }

  form {
    &.no-stock {
      #edit-submit {
        display: none;
      }

      .add-to-wishlist {
        display: inline-block;
      }
    }
  }

  .add-to-wishlist {
    @extend %button;
    @extend %button-secondary;
    display: none;
  }
}

#block-views-eshop-block-2,
#block-views-eshop-block-6 {

  .views-field-field-images {
    ul {
      li {
        img {
          display: block;
          margin: 0 auto;
        }

        blockquote {
          text-align: center;
        }
      }
    }
  }
}

#block-views-eshop-block-3 {
  @include rem(padding, 30px 0);

  .views-row {
    display: flex;
    flex-wrap: wrap;

    .views-field {
      @include rem(margin, 5px 0);
      // flex: 0 0 33.3%;
      color: $black;

      strong {
        color: $primary-color;
      }
    }
  }
}


#comments {
  .grippie {
    display: none;
  }
  .comment-block {
    @include rem(padding, 20px);
    @include rem(margin-bottom, 20px);
    border: 1px solid $gray;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .profile-picture {
      @include rem(margin-right, 10px);
      border-radius: 50%;
      width: 35px;
      height: 35px;
      overflow: hidden;
      position: relative;

      img {
        max-width: 100%;
        display: block;
        &.default-pic {
          background: $primary-color-lighter;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }

    .comment-info {
      line-height: 1.2;

      p {
        @include rem(font-size, 12px);
        margin: 0;
      }

      strong {
        @include rem(font-size, 14px);
        color: $black;
        font-weight: 400;
        display: block;
      }
    }

    form {
      flex: 0 0 100%;
      width: 100%;
      label {
        display: none;
      }
      textarea {
        padding: 0;
        border: none;
        resize:none;
        height: 50px;
      }

      .form-actions {
        margin: 0;
      }
    }
  }

  .comments-wrapper {
    @include rem(padding-top, 40px);
    border-top: 1px solid $gray;

    .comment {
      @include rem(padding, 20px 0);
      @include rem(margin-bottom, 20px);
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .content, .links {
        width: 100%;
        flex: 0 0 100%;
      }

      .links {
        a {
          color: $black-lighter;
          &:hover,
          &:focus {
            text-decoration: none;
            color: $black;
          }
        }
        .comment {
          &-reply {
            a {
              &:before {
                @include custom-font('\EA54');
                @include rem(margin-right, 5px);
                color: $primary-color;
              }
            }
          }

          &-edit {
            a {
              &:before {
                @include custom-font('\EA04');
                @include rem(margin-right, 5px);
                color: $primary-color;
              }
            }
          }

          &-delete {
            a {
              &:before {
                @include custom-font('\EA4E');
                @include rem(margin-right, 5px);
                color: $primary-color;
              }
            }
          }
        }

      }

      .user-picture {
        @include rem(margin-right, 10px);
        border-radius: 50%;
        width: 35px;
        height: 35px;
        overflow: hidden;
        position: relative;
        background: $primary-color-lighter;

        img {
          display: block;
          max-width: 100%;
        }
      }

      .comment-info {
        @include rem(font-size, 12px);
        line-height: 1.2;

        strong {
          @include rem(font-size, 14px);
          color: $black;
          display: block;
        }
      }
    }
  }

  .form-actions {
    .form-submit,
    #edit-cancel {
      @extend %button-outline;
      @include rem(margin-right, 10px);
    }

    #edit-cancel {
      text-transform: uppercase;
      display: inline-block;
      line-height: 1;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.ajax-comments-form {
  &-reply {
    textarea {
      border: 0;
      height: 50px;
    }

    label {
      display: none;
    }

    .form-actions {
      input {
        @include rem(margin-right, 10px);
      }
    }
  }

  &-edit {
    textarea {
      border: 0;
      height: 50px;
    }

    label {
      display: none;
    }

    .form-actions {
      input {
        @include rem(margin-right, 10px);
      }
    }
  }
}

#block-commerce-checkout-progress-indication {
  @include rem(margin, 40px 0);

  @include xs {
    display: none;
  }

  ol {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      @include rem(padding, 26px);
      list-style: none;
      float: none;
      width: auto;
      counter-increment: step-counter;
      position: relative;
      border-top: 1px solid $black;
      text-transform: uppercase;
      font-weight: 600;
      color: $black;

      &.active {
        color: $primary-color-darker;

        &:before {
          border-color: $primary-color-darker;
          color: $primary-color-darker;
        }
      }

      &.visited {
        color: $primary-color;

        &:before {
          border-color: $primary-color;
          color: $primary-color;
        }
      }

      &:before {
        @include rem(font-size, 24px);
        font-weight: 600;
        content: counter(step-counter);
        border: 1px solid $black;
        display: inline-block;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        color: $black;
        background: $white;
        top: -14px;
        left: 50%;
        margin-left: -14px;
        z-index: 20;
      }

      span.after {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -12px;
        display: block;

        &:before {
          @include custom-font("\EA51");
          @include rem(font-size, 9px);
          font-weight: 300;
        }
      }

      &.first {
        &:after {
          display: block;
          content:"";
          height: 3px;
          width: 50%;
          position: absolute;
          top: -1px;
          left: 0;
          z-index: 5;
          background: $white;
        }
      }

      &.last {
        &:after {
          display: block;
          content:"";
          height: 3px;
          width: 50%;
          position: absolute;
          top: -1px;
          right: 0;
          z-index: 5;
          background: $white;
        }

        span.after {
          display: none;
        }
      }
    }
  }
}

#block-menu-menu-faq {
  h2 {
    @include rem(padding, 0 0 5px);
    border-bottom: 1px solid $gray;
    display: inline-block;
  }

  ul.menu {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 0;
      padding: 0;

      a {
        @include rem(padding, 5px 0);
        color: $black;
        text-transform: uppercase;
        display: block;

        &.active-trail {
          color: $primary-color-darker;
        }

        &:hover,
        &:focus {
          color: $primary-color;
          text-decoration: none;
        }
      }
    }
  }
}

#block-views-wish-block-1 {
  @include rem(margin-right, 10px);
  position: relative;
  cursor: pointer;

  .view-header,
  .view-empty {
    @include rem(font-size, 10px);
    width: 30px;
    height: 25px;
    text-align: center;
    position: relative;
    line-height: 30px;

    &:before {
      @include custom-font('\EA4D');
      @include rem(font-size, 24px);
      position: absolute;
      left: 0;
      bottom: 0;
      color: $warning-color;
    }
  }

  .content {
    > .view-wish {
      > .view-content {
        @include rem(padding, 20px);
        @include rem(width, 300px);
        position: absolute;
        top: 43px;
        right: 0;
        background: $white;
        box-shadow: 0 0 5px $gray-lighter;
        z-index: 200;
        display: none;

        &:before {
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid $white;
          display: block;
          content:"";
          position: absolute;
          top: -10px;
          right: 5px;
        }

        .view-footer {
          @include rem(margin-top, 20px);
          text-align: right;
        }
      }
    }
  }
}

#block-views-header-block {
  @include rem(padding, 100px 0);
  @include rem(margin-bottom, 40px);
  position: relative;

  @include xs {
    @include rem(padding, 40px 0);
  }

  &:before {
    content:'';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba($black, 0.7);
    z-index: 5;
  }

  .views-field-field-image {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    img {
      position: absolute;;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }

  .views-field-field-intro {
    @include rem(margin-bottom, 100px);
    position: relative;
    z-index: 30;
    text-align: center;
    color: $white;

    @include xs {
      @include rem(margin-bottom, 70px);
    }

    h1 {
      @include rem(font-size, 38px);
      color: $primary-color;

      @include xs {
        @include rem(font-size, 27px);
      }
    }

    .btn {
      text-transform: uppercase;
    }
  }
}

#block-block-1 {
  @include rem(margin-top, -100px);
  @include rem(margin-bottom, 100px);
  @include rem(padding, 5px 50px 50px);
  margin-right: 2%;
  margin-left: 12%;
  width: 35%;
  flex: 0 0 35%;
  box-sizing: border-box;
  background: $white;
  position: relative;
  z-index: 100;
  text-align: center;

  @include xs {
    @include rem(margin, 0 0 50px);
    width: auto;
  }

  h2 {
    @include rem(font-size, 24px);
    color: $primary-color;

    &:after {
      @include rem(margin, 10px auto);
      content: '';
      display: block;
      width: 30px;
      height: 1px;
      background: $primary-color;

    }
  }

  .btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

#block-block-2 {
  @include rem(margin-top, -100px);
  @include rem(margin-bottom, 100px);
  @include rem(padding, 5px 50px 50px);
  margin-left: 2%;
  margin-right: 12%;
  width: 35%;
  flex: 0 0 35%;
  box-sizing: border-box;
  background: $white;
  position: relative;
  z-index: 100;
  text-align: center;

  @include xs {
    @include rem(margin, 0 0 50px);
    width: auto;
  }

  h2 {
    @include rem(font-size, 24px);
    color: $secondary-color;

    &:after {
      @include rem(margin, 10px auto);
      content: '';
      display: block;
      width: 30px;
      height: 1px;
      background: $secondary-color;

    }
  }

  .btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    background: $secondary-color;
  }
}

#block-views-agenda-block {
  width: 22%;

  @include xs {
    width: auto;
  }

  h2 {
    font-weight: 400;
    text-align: center;

    &:after {
      @include rem(margin, 10px auto);
      content:'';
      display: block;
      width: 20px;
      height: 1px;
      background: $gray;
    }
  }
  > .content {
    @include rem(padding, 15px);
    text-align: center;
    background: $primary-color-lighter;
    border: 1px solid $primary-color;
  }

  .views-field-field-fin {
    color: $primary-color-darker;
    font-weight: 600;
    text-transform: uppercase;
  }

  .views-field-field-date-2 {
    text-transform: uppercase;
  }

  h3 {
    a {
      color: $black;
      font-weight: 700;
    }
  }

  .btn {
    display: block;
  }
}

#block-views-contact-block-4 {
  width: 22%;

  @include xs {
    width: auto;
  }

  h2 {
    font-weight: 400;
    text-align: center;

    &:after {
      @include rem(margin, 10px auto);
      content:'';
      display: block;
      width: 20px;
      height: 1px;
      background: $gray;
    }
  }

  > .content {
    @include rem(padding, 15px);
    text-align: center;
  }

  .btn {
    @include rem(margin-top, 15px);
    display: block;
  }

  .views-field-email,
  .views-field-street {
    @include rem(margin-bottom, 22px);
  }
}

#block-views-blog-block-2 {
  width: 56%;
  display: flex;
  flex-direction: column;

  @include xs {
    width: auto;
  }

  h2 {
    font-weight: 400;
    text-align: center;

    &:after {
      @include rem(margin, 10px auto 0);
      content:'';
      display: block;
      width: 20px;
      height: 1px;
      background: $gray;
    }
  }
  > .content {
    @include rem(padding, 0 20px);
    position: relative;
    overflow: hidden;
    flex: 1 1 auto;
    display: flex;

    @include xs {
      padding: 0;
    }

    &:after {
      background: rgba($black, 0.7);
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 20px;
      right: 20px;
      bottom: 0;

      @include xs {
        left: 0;
        right: 0;
      }
    }
  }

  .views-field-field-image {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 20px;
    right: 20px;
    transform: translateY(-50%);

    @include xs {
      left: 0;
      right: 0;
    }

    img {
      width: 100%;
    }
  }

  .views-field-created {
    position: relative;
    z-index: 5;
    color: $white;
  }

  .views-field-title {
    position: relative;
    z-index: 10;
  }

  .views-field-path {
    position: relative;
    z-index: 15;
  }

  .view,
  .view-content {
    display: flex;
    width: 100%;
  }

  .views-row {
    @include rem(padding, 15px )
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

#block-views-agenda-block-1 {
  @include rem(padding, 30px 0);
  @include rem(margin-bottom, 40px);
  position: relative;
  text-align: center;
  color: $white;

  &:before {
    content:'';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba($black, 0.7);
    z-index: 5;
  }

  .views-field-field-image {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    img {
      position: absolute;;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      display: block;
    }
  }

  .views-row {
    display: flex;
    flex-direction: column;

    .views-field-field-fin,
    .views-field-field-fin-1,
    .views-field-title {
      position: relative;
      z-index: 10;
    }

    .views-field-title {
      @include rem(margin, 100px auto);
      @include rem(padding, 0 30px);
      flex: 1 1 auto;

      @include xs {
        @include rem(margin, 60px 0);
      }

      h1 {
        color: $primary-color;
      }
    }
  }
}

#block-views-blog-block-3 {
  @include rem(padding, 30px 0);
  @include rem(margin-bottom, 40px);
  position: relative;
  text-align: center;
  color: $white;

  &:before {
    content:'';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba($black, 0.7);
    z-index: 5;
  }

  .views-field-field-image {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    img {
      position: absolute;;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      display: block;
    }
  }

  .views-row {
    display: flex;
    flex-direction: column;
    .views-field-created,
    .views-field-title,
    .views-field-field-nom {
      position: relative;
      z-index: 10;
    }

    .views-field-title {
      @include rem(margin, 100px auto);
      @include rem(padding, 0 30px);
      flex: 1 1 auto;

      @include xs {
        @include rem(margin, 60px 0);
      }

      h1 {
        color: $primary-color;
      }
    }

    .starrating {
      display: flex;
      justify-content: center;
    }
  }
}

#block-social-share-social-share {
  position: absolute;

  .share-label {
    color: $black;
    font-weight: 600;
  }

  .content {
    ul {
      border-left: 1px solid $gray;
      padding: 0;

      li {
        @include rem(padding-left, 10px);
        margin: 0;
        list-style: none;

        a {
          color: $black-lighter;

          &:hover,
          &:focus {
            color: $primary-color;
          }
        }
      }
    }
  }
}

#block-views-blog-block-4 {
  @include rem(margin, 60px 200px);

  @include xs {
    @include rem(margin, 60px 0);
  }
}

#block-menu-menu-les-jardins-du-floril-ge {
  @include rem(padding-bottom, 20px);
  @include rem(margin-bottom, 20px);
  border-bottom: 1px solid $gray-lighter;
}

.timer {
  display: flex;

  div {
    @include rem(margin, 0 5px);

    strong {
      color: $white;
    }
  }

  a {
    color: $white;
  }
}

#block-webform-client-block-3516 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($black, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 40;
  display: none;

  h2,
  .content {
    @include rem(padding, 20px);
    background: $white;
    width: 90%;
    max-width: 450px;
  }

  h2 {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      &:before {
        @include custom-font("\EA5D");
      }
    }
  }

  .webform-component-textfield {
    float: left;
    width: 49%;

    &.webform-component--nom {
      margin-right: 2%;
    }

    input {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .webform-component-email {
    clear: both;

    input {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .webform-component-date {
     .webform-container-inline {
       display: flex;
       align-items: center;

       .form-item {
         @include rem(margin-right, 5px);
         flex: 1 1 auto;
       }
     }
   }

   textarea {
     @include rem(padding, 5px 10px);
     border: 1px solid $gray-lighter;
   }
}

.mfp-wrap {
  .mfp-content,
  .mfp-figure,
  figure,
  img.mfp-img{
    //height: 100%;
  }
}

#block-views-eshop-block-5 {
  @include rem(padding, 1px 0px);
  background: $primary-color-lighter;

  h2 {
    @include rem(margin-left, 20px);
  }
}

#block-views-encyclop-die-block-1 {
  @include rem(margin-top, 40px);

  .view-display-id-block_1 {
    > .view-content {
      .views-row {
        columns: 2;
      }
    }

    > .view-header {
      .views-field {
        background: none;
        padding-top: 0;
        text-align: left;
      }
    }
  }

  .views-field {
    @include rem(padding, 10px);
    @include rem(margin-bottom, 10px);
    background: $gray-lightest;
    text-align: center;
    break-inside: avoid;

    &.views-field-field-classification {
      .field-content {
        display: flex;
        justify-content: center;
      }
    }

    &.views-field-field-type-de-sol {
      .item-list {
        ul {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          li {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
            box-sizing: border-box;
          }
        }
      }
    }

    &.views-field-field-int-r-ts-remarquables,
    &.views-field-field-periode,
    &.views-field-field-couleur-de-feuille,
    &.views-field-field-couleur-de-fleur,
    &.views-field-field-exposition,
    &.views-field-field-utilisation-au-jardin,
    &.views-field-field-utilisation-ailleurs {
      .item-list {
        ul {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          li {
            @include rem(margin, 0 5px 10px);
            list-style: none;
            padding: 0;
          }
        }
      }
    }

    &.views-field-field-couleur-de-feuille {
      span[class^="color-"] {
        &:before {
          background: url('../images/color-feuille.png') no-repeat;

          /* ECRAN RETINA */
          @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
          only screen and (-o-max-device-pixel-ratio: 3/2),
          only screen and (max--moz-device-pixel-ratio: 1.5),
          only screen and (max-device-pixel-ratio: 1.5) {
            background: url('../images/color-feuille.png') no-repeat;
          }
          @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
          only screen and (-o-min-device-pixel-ratio: 3/2),
          only screen and (min--moz-device-pixel-ratio: 1.5),
          only screen and (min-device-pixel-ratio: 1.5) {
            background: url('../images/color-feuille@2x.png') no-repeat;
            background-size: 41px 41px;
          }
        }
      }
    }

    &.views-field-field-couleur-de-fleur {
      span[class^="color-"] {
        &:before {
          background: url('../images/color-fleur.png') no-repeat;

          /* ECRAN RETINA */
          @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
          only screen and (-o-max-device-pixel-ratio: 3/2),
          only screen and (max--moz-device-pixel-ratio: 1.5),
          only screen and (max-device-pixel-ratio: 1.5) {
            background: url('../images/color-fleur.png') no-repeat;
          }
          @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
          only screen and (-o-min-device-pixel-ratio: 3/2),
          only screen and (min--moz-device-pixel-ratio: 1.5),
          only screen and (min-device-pixel-ratio: 1.5) {
            background: url('../images/color-fleur@2x.png') no-repeat;
            background-size: 41px 41px;
          }
        }
      }
    }

    h3 {
      @include rem(font-size, 14px);
      @include rem(margin, 0 0 10px);
      color: $secondary-color;
      text-transform: uppercase;
    }

    span[class^="tax-"] {
      font-size: 0;
      display: inline-block;
      width: 41px;
      height: 41px;
      text-align: center;
      line-height: 41px;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        @include rem(font-size, 32px);
        @include custom-font('\EA0A');
      }
    }

    span[class^="color-"] {
      font-size: 0;
      display: inline-block;
      width: 39px;
      height: 39px;

      &:before {
        content:'';
        display: block;
        width: 41px;
        height: 41px;
        margin-top: -1px;
        margin-left: -1px;
      }


    }

    span[class^="sol-"] {
      @include rem(margin-bottom, 8px);
      @include rem(padding, 0 10px);
      height: 41px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: $white;

      &:before {
        @include rem(font-size, 9px);
        @include custom-font('\EA3F');
        display: block;
      }
    }

    span {
      display: block;

      &.item {
        @include rem(font-size, 12px);
        @include rem(margin, 0 0 10px);
        @include rem(padding, 0 10px);
        padding-left: 0;
        height: 41px;
        background: $white;
        display: flex;
        align-items: center;
        width: 100%;
        box-sizing: border-box;

        &:before {
          @include custom-font('\EA17');
          @include rem(font-size, 32px);
          vertical-align: middle;
          text-align: center;
          width: 40px;
          text-align: center;
        }

        &:last-child {
          @include rem(margin-bottom, 20px);
        }
      }

      &.sol-42 {
        color: $red-tax;
      }

      &.sol-43 {
        color: $purple;
      }

      &.sol-44 {
        color: $black;
      }

      &.color-15,
      &.color-31 {
        background: $silver;
      }

      &.color-207,
      &.color-19 {
        background: $white;
      }

      &.color-12,
      &.color-26 {
        background: $blue;
      }

      &.color-208,
      &.color-209 {
        background: $crema;
      }

      &.color-14,
      &.color-20 {
        background: $yellow;
      }

      &.color-17,
      &.color-30 {
        background: $black;
      }

      &.color-206,
      &.color-21 {
        background: $orange;
      }

      &.color-18 {
        @include background-gradient(48deg, ($green 0%, $green 49%, $crema 50%));
      }

      &.color-13,
      &.color-28 {
        background: $purpre;
      }

      &.color-212,
      &.color-23 {
        background: $pink;
      }

      &.color-16,
      &.color-22 {
        background: $red-tax;
      }

      &.color-11,
      &.color-27 {
        background: $green;
      }

      &.color-29 {
        background: $brown;
      }

      &.color-24 {
        background: $violet;
      }

      &.color-25 {
        background: $purple;
      }

      &.tax-1 {
        &:before {
          @include rem(font-size, 40px);
          content:"\EA0F";
        }
      }

      &.tax-2 {
        &:before {
          content:"\EA1A";
        }
      }

      &.tax-3 {
        &:before {
          content:"\EA46";
        }
      }

      &.tax-5 {
        &:before {
          content:"\EA26";
        }
      }

      &.tax-6 {
        &:before {
          content:"\EA11";
        }
      }

      &.tax-10 {
        &:before {
          content:"\EA32";
        }
      }

      &.tax-7 {
        &:before {
          content:"\EA15";
        }
      }

      &.tax-9 {
        &:before {
          content:"\EA36";
        }
      }

      &.tax-4 {
        &:before {
          content:"\EA27";
        }
      }

      &.tax-169 {
        &:before {
          content:"\EA16";
        }
      }

      &.tax-170 {
        &:before {
          content:"\EA35";
        }
      }

      &.tax-8 {
        &:before {
          content:"\EA3D";
        }
      }

      &.tax-203 {
        &:before {
          content: '\EA3B';
        }
      }

      &.tax-215 {
        &:before {
          content: '\EA20';
        }
      }

      &.tax-204 {
        &:before {
          content: '\EA10';
        }
      }

      &.tax-216 {
        &:before {
          content: '\EA2B';
        }
      }

      &.tax-171 {
        &:before {
          content: '\EA0D';
        }
      }

      &.tax-172 {
        &:before {
          content: '\EA0E';
        }
      }

      &.tax-173 {
        &:before {
          content: '\EA14';
        }
      }

      &.tax-174 {
        &:before {
          content: '\EA1F';
        }
      }

      &.tax-52 {
        &:before {
          content: '\EA45';
        }
      }

      &.tax-49 {
        &:before {
          content: '\EA40';
        }
      }

      &.tax-50 {
        &:before {
          content: '\EA30';
        }
      }

      &.tax-51 {
        &:before {
          content: '\EA34';
        }
      }

      &.tax-217 {
        &:before {
          content: '\EA08';
        }
      }

      &.tax-180 {
        &:before {
          content: '\EA12';
        }
      }

      &.tax-178 {
        &:before {
          content: '\EA28';
        }
      }

      &.tax-183 {
        &:before {
          content: '\EA2C';
        }
      }

      &.tax-175 {
        &:before {
          content: '\EA2D';
        }
      }

      &.tax-176 {
        &:before {
          @include rem(font-size, 28px);
          content: '\EA2E';
        }
      }

      &.tax-184 {
        &:before {
          @include rem(font-size, 12px);
          content: '\EA38';
        }
      }

      &.tax-185 {
        &:before {
          content: '\EA3A';
        }
      }

      &.tax-181 {
        &:before {
          content: '\EA3C';
        }
      }

      &.tax-182 {
        &:before {
          content: '\EA3E';
        }
      }

      &.tax-177 {
        &:before {
          content: '\EA41';
        }
      }

      &.tax-179 {
        &:before {
          content: '\EA42';
        }
      }

      &.tax-194 {
        &:before {
          content: '\EA09';
        }
      }

      &.tax-221 {
        &:before {
          content: '\EA0C';
        }
      }

      &.tax-188 {
        &:before {
          content: '\EA0B';
        }
      }

      &.tax-186 {
        &:before {
          content: '\EA0A';
        }
      }

      &.tax-191 {
        &:before {
          content: '\EA2A';
        }
      }

      &.tax-193 {
        &:before {
          content: '\EA44';
        }
      }

      &.tax-210 {
        &:before {
          content: '\EA24';
        }
      }

      &.tax-218 {
        &:before {
          @include rem(font-size, 14px);
          content: '\EA1C';
        }
      }

      &.tax-219 {
        &:before {
          content: '\EA1B';
        }
      }

      &.tax-189 {
        &:before {
          content: '\EA19';
        }
      }

      &.tax-199 {
        &:before {
          content: '\EA06';
        }
      }

      &.tax-196 {
        &:before {
          content: '\EA22';
        }
      }

      &.tax-197 {
        &:before {
          content: '\EA21';
        }
      }

      &.tax-195 {
        &:before {
          content: '\EA23';
        }
      }

      &.tax-198 {
        &:before {
          content: '\EA25';
        }
      }

      &.tax-211 {
        &:before {
          content: '\EA2F';
        }
      }

      &.tax-214 {
        &:before {
          content: '\EA33';
        }
      }

      &.tax-213 {
        &:before {
          content: '\EA31';
        }
      }

      &.tax-205 {
        &:before {
          content: '\EA37';
        }
      }

      &.tax-222 {
        &:before {
          content: '\EA13';
        }
      }

      &.tax-201 {
        &:before {
          content: '\EA18';
        }
      }

      &.tax-202 {
        &:before {
          @include rem(font-size, 14px);
          content: '\EA1D';
        }
      }

      &.tax-200 {
        &:before {
          content: '\EA39';
        }
      }

      &.tax-223 {
        &:before {
          content: '\EA43';
        }
      }

      &.tax-233 {
        &:before {
          content: '\EA41';
        }
      }
    }
  }
  .field-hauteur {
    @include rem(margin, 0 20px 10px);
    @include rem(padding, 0 10px);
    height: 41px;
    background: $white;
    display: flex;
    align-items: center;

    &:before {
      @include custom-font('\EA29');
      @include rem(font-size, 32px);
      @include rem(margin-right, 10px);
      vertical-align: middle;
      width: 40px;
      text-align: center;
    }
  }

  .field-encombrement {
    @include rem(margin, 0 20px 10px);
    @include rem(padding, 0 10px);
    height: 41px;
    background: $white;
    display: flex;
    align-items: center;

    &:before {
      @include custom-font('\EA07');
      @include rem(font-size, 7px);
      @include rem(margin-right, 10px);
      vertical-align: middle;
      width: 40px;
      text-align: center;
    }
  }
}

#block-views-encyclop-die-block-2 {
  @include rem(margin-top, 40px);

  blockquote {
    text-align: center;
  }

  .views-field-field-images {
    ul {
      li {
        img {
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}

#block-views-encyclop-die-block-3 {
  @include rem(margin-top, 50px);

  .views-field-body {
    h2 {
      strong {
        &:after {
          @include custom-font('\EA01');
          @include rem(font-size, 7px);
          @include rem(margin-left, 10px);
        }
      }

      &:hover {
        cursor: pointer;
      }

      &.active {
        strong{
          &:after {
            content: '\EA02';
          }
        }
      }
    }

    ul,
    h2 {
      @include rem(padding, 20px);
      margin: 0;

      &.even {
        background: lighten($gray-lightest, 3%);
        position: relative;

        &:before,
        &:after {
          display: block !important;
          content: '';
          width: 50%;
          height: 100%;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 0;
          border: 0;
          background: lighten($gray-lightest, 3%);
        }

        &:after {
          right: -50%;
        }

        &:before {
          left: -50%;
        }
      }
    }

    ul {
      @include rem(padding, 0 20px 40px 20px);
      margin: 0;

      li {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      h3 {
        margin: 0;
        @include rem(padding-top, 20px);
        @include rem(margin-bottom, 5px);
        color: $secondary-color;
        text-transform: none;
        font-weight: normal;
      }
    }
  }
}


@keyframes scaleDown {
  0% {
    transform: scale(10, 10);
    opacity: 0;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

#block-block-5,
#block-block-7 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(#000, 0.8);
  z-index: 350;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  .content {
    @include rem(padding, 30px 50px);
    background: $white;
    width: 80%;
    max-width: 450px;
    text-align: center;
    opacity: 0;
    transition: all linear 0.2s;
    animation: scaleDown 0.3s 0.2s ease-in-out forwards;
  }

  h2 {
    @include rem(font-size, 24px);

    strong {
      @include rem(font-size, 30px);
      color: $secondary-color;
    }
  }

  .btn {
    @include rem(margin-top, 20px);
    background: $secondary-color-darker;
    color: $white;

    &:hover,
    &:focus {
      background: $secondary-color;
      color: $white;
    }
  }

  a {
    color: $secondary-color-darker;

    &:hover,
    &:focus {
      color: $secondary-color;
    }

    &.connect {
      &:before {
        @include custom-font('\EA5E');
        @include rem(margin-right, 5px);
        vertical-align: middle;
      }
    }
  }
}

#sliding-popup {
  width: 100%;
  background: $primary-color-lighter;
  box-shadow: 0 0 10px $gray;

  .popup-content {
    @extend %container;
    @include rem(padding, 15px 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p,
  h2 {
    margin: 0;
  }

  .agree-button {
    @extend %button;
    @extend %button-secondary;
    border: 1px solid $primary-color;
  }

  .decline-button {
    @extend %button;
    @extend %button-outline;
    @include rem(font-size, 14px);
  }

  .find-more-button {
    @include rem(margin-top, 10px);
    background: none;
    border: none;
    padding: 0;
    font-weight: bold;
  }
}

#block-views-abonnement-block-1 {
  @include rem(padding, 20px 20px 40px);
  @include rem(margin-right, 20px);
  text-align: center;
  background: $secondary-color-darker;

  &:before {
    @include rem(margin, 0 auto 20px);
    width: 100px;
    height: 100px;
    display: block;
    content:'';
    background: $white url('../images/logo-encyclo.svg') center center no-repeat;
    background-size: 70px auto;
    border-radius: 50%;
  }

  .view-footer {
    @include rem(margin-top, 15px);
    color: $white;

    .logged-in & {
      display: none;
    }

    .btn {
      @include rem(margin-top, 5px);
      color: $secondary-color;
      border-color: $secondary-color;

      &:hover,
      &:focus {
        background: $secondary-color;
        color: $white;
      }
    }
  }

  .commerce-product-title {
    font-family: $headings-font-family;
    font-size: $headings-2-font-size;
    font-weight: 700;
    color: $secondary-color;
    text-transform: uppercase;

    &:after {
      @include rem(margin, 10px auto 20px);
      display: block;
      width: 20px;
      height: 4px;
      background: $secondary-color;
      content: '';
    }

    .commerce-product-title-label {
      display: none;
    }
  }

  .btn-primary {
    @include rem(margin-top, 20px);
    background: $secondary-color;
    color: $secondary-color-darker;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background: $secondary-color-lighter;
    }
  }

  input {
    @include rem(margin-top, 10px);
    background: $secondary-color;

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }

  p {
    color: $white;
  }

  .field-type-commerce-price {
    @include rem(font-size, 24px);
    color: $secondary-color;
    font-family: $headings-font-family;
    font-weight: 700;

    // .field-item {
    //
    //   .i18n-fr & {
    //     &:after {
    //       content:"/mois";
    //     }
    //   }
    // }
  }

  .slick-arrow {
    background: none;
    color: $secondary-color-darker;

    &:hover,
    &:focus {
      color: $secondary-color;
      background: none;
    }
  }

  .slick-prev,
  .slick-next {
    &:before {
      color: $secondary-color-darker;
    }
  }

  .slick-prev {
    left: -50px;
  }

  .slick-next {
    right: -50px;
  }
}

#block-block-6 {
  @include rem(margin-top, 30px);
  @include rem(margin-right, 20px);
  text-align: center;
}

#block-block-8 {
  @include rem(margin-bottom, 30px);
  @include rem(padding, 20px 60px);
  background: $gray-lightest;
  position: relative;

  &:before {
    @include custom-font('\EA4B');
    position: absolute;
    top: 0;
    left: 10px;
    background: $primary-color;
    color: $white;
    padding: 20px 10px 10px;
  }

  p {
    strong {
      color: $warning-color;
    }

    &:first-child {
      strong {
        color: $primary-color;
      }
    }
  }
}
