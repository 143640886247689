// ADMIN
$white: #eff3f3;

$grey: #566472;
$dark-grey: #34495e;

$turquoise: #16a085;

$red: #ff6c60;
$dark-red: #cd3f3d;



#tools {
  background: $dark-grey;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 499;

  .region-tools {
    display: flex;
    justify-content: space-between;
  }
  .menu {
    padding: 0;
    margin: 0;
    display: flex;
    li {
      list-style: none;
      padding: 0;
      margin: 0;
      position: relative;
      &.first {
        a {
          font-weight: bolder;
        }
      }
      a {
        display: block;
        width: 100%;
        box-sizing: border-box;
        color: $white;
        text-decoration: none;
        padding: 1.4em 1.2em;
        text-transform: uppercase;
        font-family: $global-font-family;
        line-height: 1;

        &[class^="icon-"] {
          &:before {
            width: 20px;
            margin-right: 5px;
          }
        }
        &:before {
          margin-right: 5px;
        }
        &:hover,
        &:focus {
          background: $turquoise;
        }
        &.icon-logout {
          background: $red;

          &:before {
            @include custom-font('\EA5C');
          }
          &:hover,
          &:focus {
            background: $dark-red;
          }
        }
        &.home{
          &:hover,
          &:focus {
            background: none;
            color: $turquoise;
          }
        }

        &.icon-plus {
          &:before {
            @include custom-font('\EA57');
          }
        }

        &.icon-book {
          &:before {
            @include custom-font('\EA55');
          }
        }

        &.icon-product {
          &:before {
            @include custom-font('\EA58');
          }
        }

        &.icon-blog {
          &:before {
            @include custom-font('\EA56');
            @include rem(font-size, 10px);
            vertical-align: middle;
          }
        }

        &.icon-agenda {
          &:before {
            @include custom-font('\EA5A');
          }
        }

        &.icon-eye {
          &:before {
            @include custom-font('\EA59');
          }
        }

        &.icon-other {
          &:before {
            @include custom-font('\EA5B');
          }
        }
      }
      .menu {
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        background: $grey;
        min-width: 320px;
        li {
          a{
            font-weight: normal;

            &:hover,
            &:focus {
              background: darken($turquoise, 10%);
            }
          }
          &.first {
            a{
              font-weight: normal;
            }
          }
        }
      }
      &:hover {
        > a {
          background: $turquoise;
        }
        .menu {
          display: block;
        }
      }
    }
  }
}
.logged-in.role-editor {
  padding-top: 54px;
}
