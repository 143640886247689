.page-encyclopedie,
.node-type-encyclopedie {

  header {
    .header__site-name {
      height: 100%;

      a {
        width: 110px;
        height: 95%;
        background: url('../images/logo-encyclo.svg') left bottom no-repeat;
      }
    }
  }

  #block-commerce-checkout-progress-indication {
    ol {
      li {
        &.visited {
          color: $secondary-color;

          &:before {
            color: $secondary-color;
            border-color: $secondary-color;
          }
        }

        &.active {
          color: $secondary-color-darker;

          &:before {
            color: $secondary-color-darker;
            border-color: $secondary-color-darker;
          }
        }
      }
    }
  }

  .checkout-completion-message {
    strong,
    a {
      color: $secondary-color;
    }

    a {
      &:hover,
      &:focus {
        color: $secondary-color-darker;
      }

      &.btn {
        color: $white;
        background: $secondary-color;

        &:hover,
        &:focus {
          background: $secondary-color-darker;
        }
      }
    }
  }

  #block-system-main-menu {
    ul {
      &.menu {
        li {
          a {
            // &.active-trail,
            // &:hover,
            // &:focus {
            //   color: $secondary-color;
            // }

            &.btn-primary {
              &.active-trail,
              &:hover,
              &:focus {
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  #block-easy-breadcrumb-easy-breadcrumb {
    .content {
      .easy-breadcrumb {
        span {
          a {
            color: $secondary-color;
          }
        }
      }
    }
  }

  #prefooter {
    background: $secondary-color;

    form {
      .webform-submit {
        background: $secondary-color-darker;
      }
    }
  }

  footer {
    .copyright {
      background: $secondary-color-lighter;

      .container {
        p {
          color: $secondary-color-darker;
        }
      }
    }

    a {
      color: $secondary-color;
    }
  }
}

.page-encyclopedie {
  .item-list {
    .pager {
      li {
        &.pager-next,
        &.pager-last,
        &.pager-previous,
        &.pager-first {
          a {
            color: $secondary-color;
            border-color: $secondary-color;
          }
        }

        &.pager-current {
          color: $secondary-color;
        }

        a {
          &:hover,
          &:focus {
            color: $secondary-color;
          }
        }
      }
    }
  }
}

.view-encyclop-die {
  &.view-display-id-page {
    .view-content {
      display: flex;
      flex-wrap: wrap;

      .views-row {
        @include rem(padding-bottom, 15px);
        width: 30%;
        margin-bottom: 5%;
        box-shadow: 0px 0px 0px 1px $gray-lighter;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;

        @include xs {
          width: 100%;
        }

        &:nth-child(3n+2) {
          margin: 0 5% 5%;

          @include xs {
            margin-right: 0;
            margin-left: 0;
          }
        }

        .views-field-field-images {

          img {
            display: block;
            width: 100%;
          }

          blockquote {
            display: none;
          }
        }

        .views-field-title {
          @include rem(margin-bottom, 15px);
          @include rem(margin-top, 10px);
          @include rem(padding, 0 20px);

          a {
            color: $black;
            font-weight: 600;
          }
        }

        .views-field-view-node {
          a {
            @extend %button;
            @extend %button-secondary;
            background: $secondary-color-darker;

            &:hover,
            &:focus {
              background: $secondary-color;
            }
          }
        }
      }
    }
  }
}

#block-views-exp-encyclop-die-page {
  .views-reset-button {
    input {
      color: $secondary-color;
    }
  }

  .views-submit-button {
    input {
      background: $secondary-color-darker;

      &:hover,
      &:focus {
        background: $secondary-color;
      }
    }
  }

  .views-exposed-widget{
    &#edit-title-wrapper {
      .bef-select-as-links {
        > .form-item {
          a {
            background: $secondary-color-lighter;
            color: $secondary-color-darker;

            &.active,
            &:hover,
            &:focus {
              background: $secondary-color-darker;
              color: $white;
            }
          }
        }
      }
    }
  }
}
