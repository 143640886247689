@font-face {
    font-family: 'icofont';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/icofont.woff') format('woff'),
         url('../fonts/icofont.woff2') format('woff2');
}

.icon[class^='icon-'],
.icon[class*=' icon-'] {
    display: inline-block;
    font-family: 'icofont';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
}

$icons: (
    arrow-bottom: '\EA01',
    arrow-top: '\EA02',
    cart: '\EA03',
    edit: '\EA04',
    refresh: '\EA05',
    icon-ecorce: '\EA06',
    encombrement: '\EA07',
    icon-alignement: '\EA08',
    icon-alim-feuille: '\EA09',
    icon-alim-fruit: '\EA0A',
    icon-alim-racine: '\EA0B',
    icon-alim-tige: '\EA0C',
    icon-annuel: '\EA0D',
    icon-aquatique: '\EA0E',
    icon-arbre: '\EA0F',
    icon-automne: '\EA10',
    icon-bambou: '\EA11',
    icon-bonzai: '\EA12',
    icon-boule: '\EA13',
    icon-bulbeuse: '\EA14',
    icon-cactee: '\EA15',
    icon-carnivore: '\EA16',
    icon-climat: '\EA17',
    icon-colonnaire: '\EA18',
    icon-condiment: '\EA19',
    icon-conifere: '\EA1A',
    icon-construction: '\EA1B',
    icon-cosmetique: '\EA1C',
    icon-couvre-sol: '\EA1D',
    icon-favorite: '\EA1E',
    icon-epiphyte: '\EA1F',
    icon-ete: '\EA20',
    icon-feuillage-persistant: '\EA21',
    icon-feuillage: '\EA22',
    icon-fleur: '\EA23',
    icon-fleuristerie: '\EA24',
    icon-fruit: '\EA25',
    icon-graminee: '\EA26',
    icon-grimpante: '\EA27',
    icon-haie: '\EA28',
    icon-hauteur: '\EA29',
    icon-herboristerie: '\EA2A',
    icon-hiver: '\EA2B',
    icon-interieur: '\EA2C',
    icon-isole: '\EA2D',
    icon-massif: '\EA2E',
    icon-melifere: '\EA2F',
    icon-mi-ombre: '\EA30',
    icon-miniature: '\EA31',
    icon-mousse: '\EA32',
    icon-nain: '\EA33',
    icon-ombre: '\EA34',
    icon-orchidee: '\EA35',
    icon-palmier: '\EA36',
    icon-parfum: '\EA37',
    icon-parterre: '\EA38',
    icon-pleureur: '\EA39',
    icon-pot: '\EA3A',
    icon-printemps: '\EA3B',
    icon-rocaille: '\EA3C',
    icon-rosier: '\EA3D',
    icon-serre: '\EA3E',
    icon-sol: '\EA3F',
    icon-soleil: '\EA40',
    icon-sous-bois: '\EA41',
    icon-topiere: '\EA42',
    icon-tortueux: '\EA43',
    icon-toxique: '\EA44',
    icon-vent: '\EA45',
    icon-vivace: '\EA46',
    icon-back: '\EA47',
    icon-list: '\EA48',
    icon-perso: '\EA49',
    purchase: '\EA4A',
    icon-attention: '\EA4B',
    icon-check: '\EA4C',
    icon-cloud: '\EA4D',
    icon-delete: '\EA4E',
    icon-exclamation: '\EA4F',
    icon-first: '\EA50',
    icon-last: '\EA51',
    icon-next: '\EA52',
    icon-prev: '\EA53',
    icon-reply: '\EA54',
    book: '\EA55',
    newspaper: '\EA56',
    plus: '\EA57',
    product: '\EA58',
    show: '\EA59',
    calendar: '\EA5A',
    paper: '\EA5B',
    logout: '\EA5C',
    close: '\EA5D',
    log: '\EA5E',
    search: '\EA60',
    premium: '\EA5F'
);

@each $name, $icon in $icons {
    .icon-#{$name}::before {
        content: $icon;
    }
}