header {
  background: $gray-lightest;

  .front &,
  .page-blog &,
  .node-type-article &,
  .node-type-faq & {
    background: $white;
  }

  .section-header {
    @include rem(padding, 15px 0);
    display: flex;

    @include xs {
      position: relative;
    }
  }

  .region-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }

  .header__site-name {
    margin: 0;
    @include rem(margin-right, 30px);

    a {
      display: block;
      width: 39px;
      height: 116px;
      background: url('../images/logo-green.png') no-repeat;
      text-indent: -9999px;

      /* ECRAN RETINA */
      @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
      only screen and (-o-max-device-pixel-ratio: 3/2),
      only screen and (max--moz-device-pixel-ratio: 1.5),
      only screen and (max-device-pixel-ratio: 1.5) {
      	background: url('../images/logo-green.png') no-repeat;
      }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
      only screen and (-o-min-device-pixel-ratio: 3/2),
      only screen and (min--moz-device-pixel-ratio: 1.5),
      only screen and (min-device-pixel-ratio: 1.5) {
        background: url('../images/logo-green@2x.png') no-repeat;
        background-size: 39px 116px;
      }
    }
  }

  #header {
    flex:1;
  }
}
