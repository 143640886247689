.form-type-select {

  label {
    text-align: left;
  }

  select {
    -webkit-appearance: button;
    -webkit-user-select: none;
    border-radius: 0;
    background: $primary-color-lighter url('../images/arrow-select.png') 95% center no-repeat;
    border: 1px solid $primary-color;
    color: $primary-color;
    font-size: inherit;
    overflow: hidden;
    padding: 10px 3%;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    /* ECRAN RETINA */
    @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
    only screen and (-o-max-device-pixel-ratio: 3/2),
    only screen and (max--moz-device-pixel-ratio: 1.5),
    only screen and (max-device-pixel-ratio: 1.5) {
    	background: $primary-color-lighter url('../images/arrow-select.png') 95% center no-repeat;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
      background: $primary-color-lighter url('../images/arrow-select@2x.png') 95% center no-repeat;
      background-size: 8px 7px;
    }
  }
}

.form-item-quantity,
.views-field-edit-quantity .form-item {
  position: relative;
  display: inline-block;
  margin: 0;
  margin-right: -5px;
  z-index: 1;
  border: 1px solid $primary-color;
  background: $primary-color-lighter;

  > label {
    display: none;
  }

  .form-text {
    @include rem(padding, 12px 25px 12px 10px);
    @include rem(width, 30px);
    border: none;
    background: none;
    text-align: right;
  }

  .commerce-quantity-plusminus-link {
    position: absolute;
    right: 5px;
    height: 15px;

    a {
      color: $black;
      display: block;
      border-radius: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      width: 15px;
      height: 15px;
      margin: 0;
      text-decoration: none;
      text-indent: -9999px;

      &:hover,
      &:focus {
        background: none;
        text-decoration: none;
      }

      &:before {
        height: 7px;
        line-height: 0.7;
      }
    }

    &-increase {
      top: 4px;
      a {
        background: url('../images/arrow-top.png') center center no-repeat;
        /* ECRAN RETINA */
        @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
        only screen and (-o-max-device-pixel-ratio: 3/2),
        only screen and (max--moz-device-pixel-ratio: 1.5),
        only screen and (max-device-pixel-ratio: 1.5) {
        	background: url('../images/arrow-top.png') center center no-repeat;
        }
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (-o-min-device-pixel-ratio: 3/2),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
          background: url('../images/arrow-top@2x.png') center center no-repeat;
          background-size: 7px 7px;
        }

        &:hover,
        &:focus {
          background: url('../images/arrow-top.png') center center no-repeat;
          /* ECRAN RETINA */
          @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
          only screen and (-o-max-device-pixel-ratio: 3/2),
          only screen and (max--moz-device-pixel-ratio: 1.5),
          only screen and (max-device-pixel-ratio: 1.5) {
          	background: url('../images/arrow-top.png') center center no-repeat;
          }
          @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
          only screen and (-o-min-device-pixel-ratio: 3/2),
          only screen and (min--moz-device-pixel-ratio: 1.5),
          only screen and (min-device-pixel-ratio: 1.5) {
            background: url('../images/arrow-top@2x.png') center center no-repeat;
            background-size: 7px 7px;
          }
        }
      }
    }

    &-decrease {
      bottom: 4px;
      a {
        background: url('../images/arrow-bottom.png') center center no-repeat;

        /* ECRAN RETINA */
        @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
        only screen and (-o-max-device-pixel-ratio: 3/2),
        only screen and (max--moz-device-pixel-ratio: 1.5),
        only screen and (max-device-pixel-ratio: 1.5) {
        	background: url('../images/arrow-bottom.png') center center no-repeat;
        }
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (-o-min-device-pixel-ratio: 3/2),
        only screen and (min--moz-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
          background: url('../images/arrow-bottom@2x.png') center center no-repeat;
          background-size: 7px 7px;
        }

        &:hover,
        &:focus {
          background: url('../images/arrow-bottom.png') center center no-repeat;

          /* ECRAN RETINA */
          @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
          only screen and (-o-max-device-pixel-ratio: 3/2),
          only screen and (max--moz-device-pixel-ratio: 1.5),
          only screen and (max-device-pixel-ratio: 1.5) {
          	background: url('../images/arrow-bottom.png') center center no-repeat;
          }
          @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
          only screen and (-o-min-device-pixel-ratio: 3/2),
          only screen and (min--moz-device-pixel-ratio: 1.5),
          only screen and (min-device-pixel-ratio: 1.5) {
            background: url('../images/arrow-bottom@2x.png') center center no-repeat;
            background-size: 7px 7px;
          }
        }
      }
    }
  }
}

#login-forms {
  overflow: hidden;

  > div {
    width: 48%;
    float: left;

    @include xs {
      @include rem(margin-bottom, 50px);
      width: 100%;
      float: none;
    }

    &#login {
      margin-right: 4%;
    }
  }

  h2 {
    @include rem(padding, 5px 0);
    @include rem(margin-bottom, 30px);
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    text-align: center;
  }

  input[type="text"],
  input[type="password"] {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $gray-lighter;
  }

  .login-forgot {
    a {
      @include rem(font-size, 12px);
      font-style: italic;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
        color: $primary-color-darker;
      }
    }
  }
  .form-actions {
    @include rem(margin-top, 30px);
    text-align: center;
    clear: both;

    .form-submit,
    .btn-secondary {
      @include rem(padding, 7px 10px);
    }

    .btn-secondary {
      line-height: 1;
    }
  }

  .field-name-field-prenom,
  .field-name-field-nom {
    width: 48%;
    float: left;
  }

  .field-name-field-prenom {
    margin-right: 4%;
  }

  .field-type-location,
  .field-name-field-adresse {
    clear: both;

    fieldset {
      border: none;
      padding: 0;
      margin: 0;
    }
  }

  .fieldset-legend {
    display: none;
  }

  .form-item-field-facturation-und-0-postal-code {
    width: 30%;
    margin-right: 3%;
    float: left;
  }

  .form-item-field-facturation-und-0-city {
    width: 67%;
    float: left;
    clear: none;
  }

  .form-item-field-facturation-und-0-province {
    clear: both;
  }

  .form-item-field-facturation-und-0-street,
  .form-item-field-adresse-und-0-country {
    margin-top: 0;
  }
}

.form-type-bef-checkbox {
  label {
    position: relative;

    &:before {
      @include rem(margin-right, 10px);
      display: inline-block;
      content: '';
      background: $primary-color-lighter;
      border: 1px solid $gray;
      width: 15px;
      height: 15px;
      vertical-align: middle;
    }
  }

  input[type="checkbox"] {
    display: none;

    &:checked + label {
      &:after {
        @include custom-font('\EA4C');
        position: absolute;
        left: 4px;
        top: 2px;
        color: $primary-color-darker;
      }
    }
  }
}

.form-item-field-couleur-de-feuille-tid,
.form-item-field-couleur-de-fleur-tid {
  .form-type-bef-checkbox {
    label {
      &:before {
        display: none;
      }
    }
  }
}
