.btn {
  @extend %button;

  &-primary {
    @extend %button-primary;
  }

  &-secondary {
    @extend %button-secondary;
  }

  &-outline {
    @extend %button-outline;
  }

  &-encyclo {
    @extend %button-encyclo;
  }
}

.form-submit {
  @extend %button;
  @extend %button-secondary;
  @include rem(padding, 7px 15px);
  border: none;
  cursor: pointer;
}

.checkout-buttons {
  clear: both;
  border: none;
  padding: 0;
  position: relative;
  flex: 0 0 100%;

  .button-operator {
    display: none;
  }

  .checkout-continue {
    position: absolute;
    right: 0;
  }

  .checkout-cancel,
  .checkout-back {
    text-decoration: underline;
    color: $primary-color;
    text-transform: none;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $primary-color-darker;
    }
  }

  .fieldset-wrapper {
    &:before {
      @include custom-font('\EA53');
      @include rem(font-size, 12px);
      font-weight: 300;
      color: $primary-color;
      vertical-align: middle;

    }
  }
}
