#toolbar {
  position: absolute;
  z-index: 600;
}
.page-eshop {
  .region-sidebar-first {
    border-right: 1px solid $gray-lighter;

    @include xs {
      border-right: none;
    }
  }
}
.region-sidebar-first {
  @include rem(padding-right, 10px);
  @include rem(margin-right, 10px);

  @include xs {
    margin-right: 0;
    padding-right: 0;
  }
}
#navigation {

  .front & {
    background: $white;
  }

  .container {
    width: 100%;
    max-width: none;
  }

  #block-easy-breadcrumb-easy-breadcrumb {
    @extend %container;
  }
}

.region-sidebar-first {
  ul.menu {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      padding: 0;
      margin: 0;

      a {
        @include rem(padding, 5px 0);
        text-transform: uppercase;
        display: block;
        color: $black;

        &:hover,
        &:focus,
        &.active {
          color: $primary-color;
          text-decoration: none;
        }
      }
    }
  }
}
